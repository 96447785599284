import { RECEIVE_LISTING, LISTING_ERROR } from 'redux/actions';

const INITIAL_STATE = {
  listing: {},
  error: null
};

/**
 * Get Store data from teespring
 * @param  {Object} state      Redux State
 * @param  {Object} action     Action data
 * @return {Object}            Returns the state
 */
const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_LISTING:
      return {
        ...state,
        listing: action.listingData
      };
    case LISTING_ERROR:
      return {
        ...state,
        error: action.err
      };
    default:
      return state;
  }
};

export default listingReducer;
