import { useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from '@emotion/styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as Clipboard } from 'assets/PopUpShop/clipboard.svg';
import { useSelector } from 'react-redux';
import { PrimaryBtn } from './StyledMuiComponents/PrimaryBtn';
import Iframe from './Iframe';
import Highlighter from './Highlighter';
import { HubspotSurvey } from './HubspotSurvey';
import { WIDGET_LAYOUT_HEIGHTS } from '../../../constants/widgetLayouts';

function Embed(props) {
  const { storeslug, title, currency, page } = props;

  const EmbedContainer = styled.div`
    width: 100%;
    background: #ffffff;
    padding: 0;
    font-size: 16px;
    display: flex;
    line-height: 29px;
    font-family: 'DM Mono', monospace;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    margin-bottom: 24px;
    overflow: hidden;
    transition: box-shadow 0.2s linear 0.2s;
  `;

  const CodeSnippet = styled.div`
    border-radius: 12px;
    overflow: hidden;
    color: #fff;
    width: 100%;
    code {
      word-break: break-all;
      white-space: nowrap;
      display: inline-block;
      white-space: normal;
      max-width: 100%;
      padding: 16px;
    }
    pre {
      overflow: hidden;
      background: #1a0c41;
    }
    span {
      ${`&::selection {
            background: orange;
        }`}
    }
  `;

  const embedStoreParams = useSelector((state) => state.embedStoreParams);

  const iframe = (
    <Iframe
      storeslug={storeslug}
      products={embedStoreParams.productAmount}
      layout={embedStoreParams.widgetLayout}
      theme={embedStoreParams.widgetTheme}
      title={title}
      width='100%'
      height={WIDGET_LAYOUT_HEIGHTS[embedStoreParams.widgetLayout]}
      currency={currency}
      page={page}
    />
  );

  const iframeFormatted = ReactDOMServer.renderToString(iframe).replace(
    /amp;/g,
    ''
  );

  // Copy to clipboard
  const [open, setOpen] = useState(false);

  const hubspotKey = '2025-03-skip-store-drop-hubspot-survey';
  const [isHubspotSurveyVisible, setIsHubspotSurveyVisible] = useState(false);

  const hideHubspotSurvey = useCallback(() => {
    setIsHubspotSurveyVisible(false);
    localStorage.setItem(hubspotKey, '1');
  }, []);

  const showHubspotSurvey = useCallback((event) => {
    if (!localStorage.getItem(hubspotKey)) {
      event?.preventDefault();
      setIsHubspotSurveyVisible(true);
    }
  }, []);

  useEffect(() => {
    const handleAnchorClick = (event) => {
      if (event.button !== 0) {
        return;
      }

      showHubspotSurvey(event);
    };

    const anchorElements = document.querySelectorAll('a[href]');

    anchorElements.forEach((anchor) =>
      anchor.addEventListener('click', handleAnchorClick)
    );

    return () => {
      anchorElements.forEach((anchor) =>
        anchor.removeEventListener('click', handleAnchorClick)
      );
    };
  }, [showHubspotSurvey]);

  const handleClick = useCallback(() => {
    setOpen(true);
    showHubspotSurvey();
  }, [showHubspotSurvey]);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }, []);

  return (
    <>
      <EmbedContainer style={{ marginBottom: 0 }}>
        <CodeSnippet style={{ marginBottom: '16px', marginTop: '10px' }}>
          <Highlighter content={iframeFormatted} />
        </CodeSnippet>
        <CopyToClipboard text={iframeFormatted}>
          <PrimaryBtn
            onClick={handleClick}
            size='large'
            style={{
              borderRadius: '16px',
              padding: '10px 16px',
              width: '100%'
            }}
          >
            <Clipboard /> Copy code
          </PrimaryBtn>
        </CopyToClipboard>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={handleClose}
          message='Copied to clipboard'
        />
      </EmbedContainer>
      {isHubspotSurveyVisible && (
        <HubspotSurvey
          onIgnore={hideHubspotSurvey}
          onSubmit={hideHubspotSurvey}
        />
      )}
    </>
  );
}

Embed.propTypes = {
  storeslug: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  txtcolor: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  currency: PropTypes.string,
  page: PropTypes.number,
  onCopy: PropTypes.func
};

Embed.defaultProps = {
  bgcolor: '',
  txtcolor: '',
  width: '100%',
  height: 420,
  currency: '',
  storeslug: '',
  page: 1
};

export default Embed;
