/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCookie, useZendesk } from 'hooks';
import { useFeatureFlag, FLAGS } from 'lib/featureFlags';
import { migrateCollections } from 'redux/actions';
import { initialize } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Cookies from 'universal-cookie';
import Routes from 'routes';
import Toaster from 'components/Toaster';
import { HelmetProvider } from 'react-helmet-async';
import { osName, deviceType } from 'react-device-detect';
import { IS_PRODUCTION, LAUNCHDARKLY_ID } from '../constants';
import ErrorHandler from './ErrorHandler';
import BpConnect from './BpConnect';
import '@teespring/ts-components/dist/styles/base-styles.scss';
import '@teespring/ts-components/dist/styles/ts-styles.scss';
import 'styles/local-styles.scss';

export const App = () => {
  const { setValue } = useCookie();
  const showCollections = useFeatureFlag(FLAGS.SHOW_COLLECTIONS);
  const showZendeskLiveChat = useFeatureFlag(FLAGS.HAS_ZENDESK_LIVECHAT);

  const dispatch = useDispatch();

  const migrateCollectionData = useCallback(async () => {
    await dispatch(migrateCollections());
  }, [dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const iterableCampaignId = query.get('iterableCampaignId');
    const iterableTemplateId = query.get('iterableTemplateId');

    if (iterableCampaignId) setValue('iterableCampaignId', iterableCampaignId);
    if (iterableTemplateId) setValue('iterableTemplateId', iterableTemplateId);
  }, [setValue]);

  useEffect(() => {
    if (showCollections && IS_PRODUCTION) {
      migrateCollectionData();
    }
  }, [showCollections, migrateCollectionData]);

  useZendesk(showZendeskLiveChat);

  return (
    <HelmetProvider>
      <Toaster>
        <ErrorHandler>
          <BpConnect />
          <Routes />
        </ErrorHandler>
      </Toaster>
    </HelmetProvider>
  );
};

const cookies = new Cookies();

const userEmail = cookies.get('teespring_user_email') || 'unknown';
const userID = cookies.get('user_id') || 'unknown';

const context = {
  kind: 'user',
  key: userID,
  email: userEmail,
  city: 'Springfield',
  device: deviceType,
  'operating system': osName,
  custom: {
    device: 'browser',
    'operating system': 'Store'
  }
};

const options = { bootstrap: 'localStorage' };

const ldClient = initialize(LAUNCHDARKLY_ID, context, options);

export default withLDProvider({
  ldClient
})(App);
