import {
  SET_INTEGRATED,
  SET_NOT_INTEGRATED
} from '../actions/integratedConnection';

export const INITIAL_STATE = {
  isConnected: false
};

const integrationConnectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INTEGRATED: {
      return { isConnected: true };
    }
    case SET_NOT_INTEGRATED:
      return { isConnected: false };
    default:
      return state;
  }
};

export default integrationConnectionReducer;
