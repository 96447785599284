import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCustomDomains } from 'redux/actions';
import get from 'lodash/get';
import { useRouteMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useDomains = (storeId, domainStatusSection = false) => {
  const dispatch = useDispatch();
  const { domains } = useSelector((state) => state.customDomain);
  const storeDomains = get(domains, storeId, []);
  const match = useRouteMatch();
  const { newSubService } = useFlags();

  useEffect(() => {
    const getStoreDomains = async () => {
      dispatch(fetchCustomDomains(storeId, newSubService, domainStatusSection));
    };
    getStoreDomains();
  }, [storeId, dispatch, match.path, newSubService, domainStatusSection]);

  return { domains: storeDomains };
};

export default useDomains;
