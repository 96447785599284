import { useEffect, useState } from 'react';
import { Select, MenuItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DateRangeModal from '../DateRangeModal';
import { ModalId } from '../../types/analytics';
import {
  format,
  subDays,
  subMonths,
  startOfMonth,
  startOfYear
} from 'date-fns';
import calendarIcon from '../../assets/calendar.svg';

const INITIAL_MODAL_STATE: { id: ModalId } = { id: '' };
const DATEPICKER_MODAL_ID: ModalId = 'datepicker-calendar';
const CUSTOM_RANGE = 'Custom Range';

type DateRangeSelectorProps = {
  setRange: (range: { startDate: Date; endDate: Date }) => void;
};

const PRESET_TYPES = [
  ['today', 'Today', () => ({ startDate: new Date(), endDate: new Date() })],
  [
    'last7days',
    'Last 7 Days',
    () => ({ startDate: subDays(new Date(), 6), endDate: new Date() })
  ],
  [
    'last30days',
    'Last 30 Days',
    () => ({ startDate: subDays(new Date(), 29), endDate: new Date() })
  ],
  [
    'last3months',
    'Last 3 Months',
    () => ({ startDate: subMonths(new Date(), 3), endDate: new Date() })
  ],
  [
    'last6months',
    'Last 6 Months',
    () => ({ startDate: subMonths(new Date(), 6), endDate: new Date() })
  ],
  [
    'monthToDate',
    'Month to Date',
    () => ({ startDate: startOfMonth(new Date()), endDate: new Date() })
  ],
  [
    'yearToDate',
    'Year to Date',
    () => ({ startDate: startOfYear(new Date()), endDate: new Date() })
  ],
  [
    'last12Months',
    'Last 12 Months',
    () => ({ startDate: subMonths(new Date(), 12), endDate: new Date() })
  ],
  [
    'last24Months',
    'Last 24 Months',
    () => ({ startDate: subMonths(new Date(), 24), endDate: new Date() })
  ]
] as const;

// Extract types dynamically
type PresetType = (typeof PRESET_TYPES)[number][0];
const DEFAULT_PRESET: PresetType = 'last30days';

// Create lookup objects

const rangeLabels = Object.fromEntries(
  PRESET_TYPES.map(([key, label]) => [key, label])
) as Record<PresetType, string>;

const presetRanges = Object.fromEntries(
  PRESET_TYPES.map(([key, , range]) => [key, range])
) as Record<PresetType, () => { startDate: Date; endDate: Date }>;
const getPresetRange = (preset: PresetType) => presetRanges[preset]();

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ setRange }) => {
  const initialRange = getPresetRange(DEFAULT_PRESET);
  const [selectedPreset, setSelectedPreset] = useState<string>(DEFAULT_PRESET);
  const [selectedRange, setSelectedRange] = useState(initialRange);
  const [tempRange, setTempRange] = useState(initialRange);
  const [activeModal, setActiveModal] = useState(INITIAL_MODAL_STATE);

  useEffect(() => {
    setRange(selectedRange);
  }, [selectedRange, setRange]);

  const handlePresetSelect = (presetLabel: string) => {
    if (presetLabel === CUSTOM_RANGE) {
      setActiveModal({ id: DATEPICKER_MODAL_ID });
    } else {
      const range = getPresetRange(presetLabel as PresetType);
      setSelectedRange(range);
      setRange(range);
      setActiveModal(INITIAL_MODAL_STATE);
      setSelectedPreset(presetLabel);
    }
  };

  const handleApply = () => {
    setSelectedRange(tempRange);
    setRange(tempRange);
    setActiveModal(INITIAL_MODAL_STATE);
    setSelectedPreset(CUSTOM_RANGE);
  };

  const handleCancel = () => {
    setTempRange(selectedRange);
    setActiveModal(INITIAL_MODAL_STATE);
  };

  return (
    <div className='custom-select'>
      <Select
        name='dateRange'
        className='MuiSelect-root'
        value={selectedPreset}
        onChange={(e) => handlePresetSelect(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              marginTop: '-2px',
              borderRadius: '0 0 8px 8px',
              borderTop: '1px solid #EBEDF0'
            }
          }
        }}
        renderValue={(selected) =>
          selected === CUSTOM_RANGE ? (
            <Box
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
            >
              <img
                src={calendarIcon}
                alt='Calendar Icon'
                style={{ width: '20px', marginRight: '8px' }}
              />
              <span className='range-label'>
                {format(tempRange.startDate, 'MMM dd, yyyy')} -{' '}
                {format(tempRange.endDate, 'MMM dd, yyyy')}
              </span>
            </Box>
          ) : (
            <Box
              sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
            >
              <img
                src={calendarIcon}
                alt='Calendar Icon'
                style={{ width: '20px', marginRight: '8px' }}
              />
              <span className='range-label'>
                {rangeLabels[selected as PresetType]}
              </span>
            </Box>
          )
        }
        IconComponent={ExpandMoreIcon}
      >
        {Object.keys(rangeLabels).map((key) => (
          <MenuItem key={key} value={key} sx={{ fontSize: '14px' }}>
            {rangeLabels[key as PresetType]}
          </MenuItem>
        ))}
        <MenuItem
          key={CUSTOM_RANGE}
          value={CUSTOM_RANGE}
          onClick={() => setActiveModal({ id: DATEPICKER_MODAL_ID })}
          sx={{ fontSize: '14px', borderTop: '1px solid #e5e7eb' }}
        >
          {CUSTOM_RANGE}
        </MenuItem>
      </Select>

      <DateRangeModal
        tempRange={tempRange}
        setTempRange={setTempRange}
        handleApply={handleApply}
        handleCancel={handleCancel}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
      />
    </div>
  );
};

export default DateRangeSelector;
