import get from 'lodash/get';
import { useEffect } from 'react';
import { useHeaderData } from 'hooks';

const useZendesk = (showZendeskLiveChat) => {
  const { header } = useHeaderData();
  const { zendesk_token } = get(header, 'user');

  useEffect(() => {
    if (!showZendeskLiveChat) {
      return;
    }
    const triggerZendeskAuth = async () => {
      // eslint-disable-next-line no-undef
      zE('messenger', 'loginUser', (callback) => {
        callback(zendesk_token);
      });
    };

    const live_chat_script =
      'https://static.zdassets.com/ekr/snippet.js?key=3fedf976-6400-4cdc-bf0c-97d291a5d360';
    const zendeskScript = document.createElement('script');
    zendeskScript.id = 'ze-snippet';
    zendeskScript.onload = triggerZendeskAuth;
    zendeskScript.src = live_chat_script;
    document.body.appendChild(zendeskScript);

    return () => {
      document.body.removeChild(zendeskScript);
    };
  }, [showZendeskLiveChat, zendesk_token]);
};

export default useZendesk;
