import {
  RECEIVE_HEADER_LINKS,
  RECEIVE_HEADER_USER_DATA,
  UPDATE_USER_ADDRESS
} from 'redux/actions';
import get from 'lodash/get';

/**
 * Get Store data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
const headerReducer = (
  state = {
    items: [],
    user: {
      id: '',
      name: '',
      email: '',
      authToken: '',
      creatorTypeId: '',
      navLinks: [],
      address: {
        address: '',
        address2: '',
        city: '',
        country: '',
        countryAbbrv: '',
        route: '',
        state: '',
        streetNumber: '',
        zip: '',
        phone: ''
      },
      verified: true,
      tosAgreement: true,
      zendesk_token: ''
    },
    featuredNavLabels: []
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_HEADER_LINKS:
      return {
        ...state,
        items: get(action, 'headerLinks.items'),
        user: {
          ...state.user,
          navLinks: get(action, 'headerLinks.user.navLinks')
        },
        featuredNavLabels: get(action, 'headerLinks.featuredNavLabels')
      };
    case RECEIVE_HEADER_USER_DATA:
      return { ...state, user: { ...state.user, ...action.userData } };

    case UPDATE_USER_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          address: {
            ...state.user.address,
            ...action.address
          }
        }
      };
    default:
      return state;
  }
};

export default headerReducer;
