import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import { calculateProductCounts } from '../../lib/analyticsCalculations';
import { PRODUCT_COLOR } from '../../constants/charts';
import PropTypes from 'prop-types';

// Product name label to appear above its corresponding bar
const renderCustomLabel = ({ x, y, value }) => {
  if (typeof value === 'object') {
    return null;
  }
  return (
    <text
      x={x} // Align with the start of the bar
      y={y - 10} // Position the label above the bar
      textAnchor='start' // Align text to the left
      fill='#000000'
      fontSize={14}
    >
      {value}
    </text>
  );
};

const ChartMostPurchasedProduct = ({ chartData }) => {
  // count units of each product sold to get top 5 products
  const [chartHeight, setHeight] = useState('350');

  const productCountsArray = Object.entries(
    calculateProductCounts(chartData, 'product_listing')
  )
    .map(([id, { name, count }]) => ({ id, name, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5); // Get top 5 products

  useEffect(() => {
    const newChartHeight = 50 + productCountsArray.length * 50; // Adjust chart height dynamically
    setHeight(newChartHeight);
  }, [productCountsArray.length]); // update only when number of bars changes

  return (
    <ResponsiveContainer height={chartHeight} className='small'>
      <BarChart
        data={productCountsArray}
        layout='vertical' // Horizontal bars
        margin={{ top: 20, right: 50, left: 0, bottom: 5 }}
        padding={{ top: 20 }}
      >
        <XAxis hide={true} type='number' domain={[0, 'dataMax']} />
        <YAxis
          type='category'
          dataKey='name'
          hide={true} // Hide the Y-axis
        />
        <Bar
          dataKey='count'
          fill={PRODUCT_COLOR}
          barSize={20}
          isAnimationActive={false} // Disable animation to correctly display bar labels
        >
          {/* Custom label for product type */}
          <LabelList dataKey='name' content={renderCustomLabel} />
          {/* Label for counts positioned at the end of each bar */}
          <LabelList dataKey='count' position='right' fill='#000' offset={8} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

ChartMostPurchasedProduct.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      line_items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          quantity: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default ChartMostPurchasedProduct;
