import React from 'react';
import { useDispatch } from 'react-redux';
import { reorderProducts } from 'redux/actions';
import { array, func, oneOfType, string, number } from 'prop-types';
import { Draggable } from '@teespring/ts-components';
import { DEFAULT_COLUMN_KEY } from '../../../../constants';
import CollectionProductItem from '../CollectionProductItem';

const ProductSection = ({ products, collectionId, handleCheck }) => {
  const dispatch = useDispatch();

  const handleDrag = (result) => {
    dispatch(reorderProducts(result, collectionId));
  };

  const createDraggableDataset = (prods) => {
    return prods.reduce(
      (acc, curr) => {
        const cellId = curr.id.toString();
        acc.cells[cellId] = {
          id: cellId,
          content: (
            <li key={curr.id} className='collection__productitem>'>
              <CollectionProductItem
                product={curr}
                checked={curr.isChecked ?? false}
                onChange={() => handleCheck(curr.id)}
              />
            </li>
          )
        };
        acc.columns[DEFAULT_COLUMN_KEY].cellIds.push(cellId);
        return acc;
      },
      {
        cells: {},
        columns: {
          [DEFAULT_COLUMN_KEY]: {
            id: DEFAULT_COLUMN_KEY,
            title: '',
            cellIds: []
          }
        },
        columnOrder: [DEFAULT_COLUMN_KEY]
      }
    );
  };

  return (
    <Draggable
      data={createDraggableDataset(products)}
      onDragEnd={(result) => handleDrag(result)}
    />
  );
};

ProductSection.propTypes = {
  products: array.isRequired,
  collectionId: oneOfType([string, number]).isRequired,
  handleCheck: func.isRequired
};

export default ProductSection;
