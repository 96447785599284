import React from 'react';

export const IntegrationsCreateProductsCard = () => {
  return (
    <section className='integrations_page_card'>
      <img
        className='integrations_page_card_icon'
        src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Store.svg'
        alt='Store Icon'
      />
      <div className='integrations_page_card_title'>
        Oops, it looks like you haven’t created any products!
      </div>
      <button
        className='steps_button'
        id='linkTreeAddBtn'
        onClick={() =>
          window.open('https://teespring.com/design-launcher', '_self')
        }
        type='submit'
      >
        <img
          src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Pen.png'
          alt='Pen Icon'
        />
        Create your first product
      </button>
    </section>
  );
};
