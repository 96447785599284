import { node } from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bpProps, Icon, Tabs } from '@teespring/ts-components';
import BackButton from 'components/BackButton';
import find from 'lodash/find';
import get from 'lodash/get';
import './Purchases.scss';

const PurchasesWrapper = ({ children }) => {
  const tabItems = [
    { id: 'purchase-history', label: 'Purchase history' },
    { id: 'subscriptions', label: 'Subscriptions' },
    { id: 'payment-method', label: 'Payment method' }
  ];

  const history = useHistory();
  const { pathname } = useLocation();
  const { bpIsGT, bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));
  const activeTab = pathname.split('/')[2] || null;

  const onTabChange = (tabId) => {
    history.push(`/purchases/${tabId}`);
  };

  return (
    <div className='purchases'>
      <section className='purchases__content'>
        {(bpIsGT('mobileLg') || !activeTab) && (
          <>
            <section className='purchases__header'>
              <h1 className='mb1 pt4'>Payments</h1>
              <p className='sub_text pb4'>
                Review your subscriptions, purchases, and manage your preferred
                payment method
              </p>
            </section>
            <section className='purchases__tabs'>
              <div className='tabs_row'>
                <Tabs
                  className='purchases_tabs'
                  activeTab={`${activeTab}`}
                  items={tabItems}
                  onChange={onTabChange}
                />
              </div>
            </section>
          </>
        )}
        {bpIsLT('mobileLg') && activeTab && (
          <>
            <BackButton
              className='btn--transparent purchases__back_nav py3'
              pathOverride='/purchases'
            >
              <Icon name='ChevronLeft' />
              {get(find(tabItems, ['id', activeTab]), 'label', '')}
            </BackButton>
          </>
        )}
        <section className='purchases__body'>{children}</section>
      </section>
    </div>
  );
};

PurchasesWrapper.propTypes = {
  children: node.isRequired
};

export default PurchasesWrapper;
