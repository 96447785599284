import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { object } from 'prop-types';

export const IntegrationsPageAccordion = ({ section }) => {
  return (
    <div style={{ marginTop: '16px' }}>
      <Accordion
        disableGutters={true}
        elevation={0}
        sx={{
          padding: '4px 0px',
          border: '1px solid #D9D9D9',
          borderRadius: '8px !important'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div
            className='integrations_step_circle'
            style={{
              backgroundImage: `url(${section.icon})`,
              minWidth: '48px',
              height: '48px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
          <div
            className='integrations_page_accordion'
            style={{
              minHeight: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            {section.title}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ padding: '0 24px' }}>
            {section.steps.map((step, index) => {
              return (
                <li
                  key={step.step}
                  style={{ marginTop: index === 0 ? '12px' : '17px' }}
                >
                  <div
                    className='integrations_page_step_text'
                    dangerouslySetInnerHTML={{ __html: step.text }}
                  />
                  {step.note && (
                    <div className='integrations_page_step_note'>
                      <span className='integrations_page_step_note_title'>
                        Note:
                      </span>
                      {step.note}
                    </div>
                  )}
                </li>
              );
            })}
          </ol>
          {section.info && (
            <div
              className='integrations_page_step_info'
              dangerouslySetInnerHTML={{ __html: section.info }}
            />
          )}
          {section.render && section.render}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

IntegrationsPageAccordion.propTypes = {
  section: object.isRequired
};

export default IntegrationsPageAccordion;
