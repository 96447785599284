import {
  SET_FEATURE_FLAGS,
  SET_TOKEN,
  SET_USER_ID,
  SET_UNAUTHORIZED_USER,
  SET_USER_STORE_NOT_FOUND
} from '../actions/user';
import { SET_USER_STORES } from '../actions/store';
import { RECEIVE_RECENTLY_SIGNED_UP } from '../actions';

export const INITIAL_STATE = {
  featureFlags: {},
  stores: [],
  token: '',
  userId: process.env.REACT_APP_USER_ID,
  hasFetchedUserStores: false,
  isUnauthorizedUser: false,
  isUserStoreNotFound: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_STORES:
      return {
        ...state,
        stores: action.userStores,
        hasFetchedUserStores: true
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          ...action.payload
        }
      };
    case SET_UNAUTHORIZED_USER:
      return {
        ...state,
        isUnauthorizedUser: action.isUnauthorizedUser
      };
    case SET_USER_STORE_NOT_FOUND:
      return {
        ...state,
        isUserStoreNotFound: action.isUserStoreNotFound
      };
    case RECEIVE_RECENTLY_SIGNED_UP:
      return { ...state, recentlySignedUp: action.recentlySignedUp };
    default:
      return state;
  }
};

export default userReducer;
