import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import useIntegrations from 'hooks/useIntegrations';
import { useParams } from 'react-router-dom';
import IntegrationsPageStepCard from '../IntegrationsPageStepCard';
import IntegrationsPageAccordion from '../IntegrationsPageAccordian';
import YoutubeDisconnectModal from '../IntegrationsDosconnectModal/YoutubeDisconnectModal';
import { YoutubeDisconnectButton } from '../IntegrationsDisconnectButtons/YoutubeDisconnectButton';
import { IntegrationsCreateProductsCard } from '../IntegrationsCreateProductsCard';
import { isNil } from 'lodash';
import { isLinktree, isNilOrEmpty, isYoutube } from '../../../../lib/helpers';
import { IntegrationsCreatorPlaybookCard } from '../IntegrationsCreatorPlaybookCard';

const IntegrationTabButton = ({
  index,
  currentTab,
  setCurrentTab,
  children,
  logEvent
}) => {
  const changeTab = () => {
    setCurrentTab(index);
    logEvent('button.clicked', {
      button: 'information-tab',
      cta: { children }
    });
  };

  return (
    <Button
      onClick={changeTab}
      className={
        currentTab === index
          ? 'integration_page_tab_btn_active'
          : 'integration_page_tab_btn'
      }
    >
      {children}
    </Button>
  );
};

IntegrationTabButton.propTypes = {
  index: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  logEvent: PropTypes.func.isRequired
};

export const IntegrationPageTabs = ({
  children,
  currentTab,
  setCurrentTab,
  integrationSlug,
  logEvent
}) => {
  const childrens = Children.toArray(children);
  return (
    <>
      <div className='integrations_page_instructions_header'>
        {childrens.length > 1 ? (
          <div className='integration_page_tabs'>
            <>
              {childrens.map((integrationTab, index) => (
                <IntegrationTabButton
                  currentTab={currentTab}
                  integrationSlug={integrationSlug}
                  setCurrentTab={setCurrentTab}
                  index={index}
                  key={integrationTab.props.label}
                  logEvent={logEvent}
                >
                  {integrationTab.props.label}
                </IntegrationTabButton>
              ))}
            </>
          </div>
        ) : (
          <IntegrationPageSingleTab>
            {childrens[0].props.label}
          </IntegrationPageSingleTab>
        )}
      </div>
      <div className='integrations_page_content_container'>
        {childrens[currentTab]}
      </div>
    </>
  );
};

IntegrationPageTabs.propTypes = {
  children: PropTypes.node.isRequired,
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  integrationSlug: PropTypes.string.isRequired,
  logEvent: PropTypes.func.isRequired
};

const IntegrationPageSingleTab = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontFamily: 'DM Sans',
        fontSize: '24px',
        fontWeight: 500,
        color: '#08080D'
      }}
    >
      {children}
    </div>
  );
};

IntegrationPageSingleTab.propTypes = {
  children: PropTypes.node.isRequired
};

const Setup = ({ integration, integrationName, logEvent, setCurrentTab }) => {
  const { getIntegrationData } = useIntegrations();
  const { integrationSlug } = useParams();
  const showCTA = integration.shouldShowCTA;
  const isConnected = useSelector(
    (state) => state.integrationConnection.isConnected
  );

  const [error, setError] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setError(undefined);
  }, [integration]);

  const handleClick = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {(!isYoutube(integrationName) ||
        (isYoutube(integrationName) && !isConnected)) && (
        <>
          {integration.eligibility && (
            <div className='integrations_page_eligibility'>
              <div className='integrations_page_eligiblilty_title'>
                {integration.eligibilityTitle || 'Eligibility requirements'}
              </div>
              <div
                className='integrations_page_eligibility_content'
                dangerouslySetInnerHTML={{
                  __html: integration.eligibility
                }}
              />
            </div>
          )}
          <div
            className='integrations_page_setup_time'
            style={{
              margin: integration.eligibility
                ? '24px auto 16px'
                : '0px auto 16px'
            }}
          >
            {integration.setupIcon && (
              <img
                src={integration.setupIcon}
                alt='lightning bolt'
                style={{ marginRight: '8px' }}
              />
            )}{' '}
            {integration.setupTime}
          </div>
          {integration.prelude}
          {integration.steps.map((step) => (
            <IntegrationsPageStepCard key={step.step} step={step} />
          ))}
          {integration.advice && (
            <div className='integrations_page_advice'>
              <div
                className='integrations_page_eligibility_content'
                dangerouslySetInnerHTML={{
                  __html: integration.advice
                }}
              />
            </div>
          )}
          {showCTA && (
            <>
              <Button
                className='integrations_page_btn_complete'
                onClick={async () => {
                  try {
                    const integrationData = await getIntegrationData();
                    logEvent('button.clicked', {
                      button: 'setup steps complete',
                      cta: 'i have completed the steps',
                      state: integrationData?.[integrationSlug]
                    });
                    if (integrationData[integrationSlug]) {
                      setCurrentTab(1);
                      setError(undefined);
                    }
                    if (!integrationData[integrationSlug])
                      setError(
                        'The connection is incomplete. Please note that the connection activation may take some time, so check back again later or try the above steps again.'
                      );
                  } catch (err) {
                    setError(err.message);
                  }
                }}
              >
                I have completed these steps
              </Button>
              {error ? (
                <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      {isYoutube(integrationName) && isConnected && (
        <>
          <YoutubeDisconnectModal
            openModal={isModalOpen}
            closeModal={closeModal}
          />
          <section className='integrations_youtube_disconnect_setup'>
            <p>
              Your YouTube account is now connected to Spring. Visit the{' '}
              <strong>‘How to use’</strong> tab to learn how to get the most out
              of this integration.{' '}
            </p>
            <YoutubeDisconnectButton handleClick={handleClick} />
          </section>
        </>
      )}
    </>
  );
};
Setup.propTypes = {
  integration: PropTypes.object.isRequired,
  logEvent: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  integrationName: PropTypes.string.isRequired
};
IntegrationPageTabs.Setup = Setup;

const HowTo = ({ integration, integrationName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isConnected = useSelector(
    (state) => state.integrationConnection.isConnected
  );
  const { products } = useSelector((state) => state.products);
  const [hasProducts, setHasProducts] = useState(true);
  const error = useSelector((state) => state.error);

  useEffect(() => {
    if (isLinktree(integrationName)) {
      const failedToFetchProducts = !isNil(error);
      const productsAreBeingFetched = isNilOrEmpty(products);
      const userHasProducts = products.count > 0;
      setHasProducts(
        failedToFetchProducts || productsAreBeingFetched || userHasProducts
      );
    }
  }, [error, integrationName, products]);

  const handleClick = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const displayCreatorPlaybookCard =
    isLinktree(integrationName) && isConnected && hasProducts;
  const displayCreateProductsCard =
    isLinktree(integrationName) && isConnected && !hasProducts;

  return (
    <>
      {integration.prelude && (
        <div className='integrations_page_howto_prelude'>
          <div className='integrations_page_eligibility_content'>
            {isConnected && integration.preludeConnected
              ? integration.preludeConnected
              : integration.prelude}
          </div>
        </div>
      )}

      {integration.sections &&
        integration.sections.map((step) => (
          <IntegrationsPageAccordion key={step.step} section={step} />
        ))}

      {displayCreatorPlaybookCard && <IntegrationsCreatorPlaybookCard />}

      {displayCreateProductsCard && <IntegrationsCreateProductsCard />}

      {isYoutube(integrationName) && isConnected && (
        <>
          <YoutubeDisconnectModal
            openModal={isModalOpen}
            closeModal={closeModal}
          />
          <div className='integrations_page_howto_disconnect_button'>
            <YoutubeDisconnectButton handleClick={handleClick} />
          </div>
        </>
      )}
    </>
  );
};
HowTo.propTypes = {
  integration: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  integrationName: PropTypes.string.isRequired
};
IntegrationPageTabs.HowTo = HowTo;

const Manage = ({ integration }) => {
  const isConnected = useSelector(
    (state) => state.integrationConnection.isConnected
  );

  return (
    <>
      {integration.prelude && (
        <div className='integrations_page_howto_prelude'>
          {isConnected ? integration.preludeConnected : integration.prelude}
        </div>
      )}
      {isConnected &&
        integration.steps &&
        integration.steps.map((step) => (
          <IntegrationsPageStepCard key={step.step} step={step} />
        ))}
      {integration.advice && (
        <div className='integrations_page_advice'>
          <div
            className='integrations_page_eligibility_content'
            dangerouslySetInnerHTML={{
              __html: integration.advice
            }}
          />
        </div>
      )}
    </>
  );
};
Manage.propTypes = {
  integration: PropTypes.object.isRequired
};
IntegrationPageTabs.Manage = Manage;
