import { LoaderButton, ModalConductor } from '@teespring/ts-components';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useCallback } from 'react';
import { object, bool, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  editStripePaymentMethod,
  fetchPaymentMethods,
  clearModalState,
  createStripePaymentMethod,
  setActiveModal
} from 'redux/actions';
import { loadStripe } from '@stripe/stripe-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import get from 'lodash/get';
import { STRIPE_PUBLISHABLE_KEY } from '../../constants';
import {
  PURCHASE_CHECKOUT_CONFIRMATION_MODAL_ID,
  OrderCompleteModal
} from './OrderCompleteModal';
import PaymentCardModal, {
  EDIT_PAYMENT_CARD_MODAL_ID,
  ADD_PAYMENT_CARD_MODAL_ID,
  ADD_PAYMENT_CARD_RESUBSCRIBE_MODAL_ID
} from './PaymentCardModal';
import PurchaseCheckoutModal, {
  PURCHASE_CHECKOUT_MODAL_ID
} from './PurchaseCheckoutModal';
import HeaderCopy from './HeaderCopy';

import './PaymentProcessing.scss';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentProcessing = ({
  subscriptionData,
  priceOption,
  isSubscribed,
  purchaseMetaData,
  paymentModalBtn,
  buttonDisabled,
  loading,
  postPaymentAction,
  manualTrigger,
  descriptiveLogTag
}) => {
  const history = useHistory();
  const { userId } = useSelector((state) => state.user);
  const subName = get(subscriptionData, 'name');
  const dispatch = useDispatch();
  const { serviceId } = useParams();
  const currentToolId = serviceId || get(subscriptionData, 'id');
  const { newSubService } = useFlags();

  useEffect(() => {
    dispatch(fetchPaymentMethods(userId, newSubService));
  }, [dispatch, newSubService, userId]);
  const { paymentMethods } = useSelector((state) => state.subscriptions);
  const { modal: modalState, hideModals } = useSelector((state) => state.modal);

  useEffect(() => {
    dispatch(fetchPaymentMethods(userId, newSubService));
  }, [dispatch, newSubService, userId]);

  const showPurchaseCheckoutModal = useCallback(() => {
    dispatch(setActiveModal({ id: PURCHASE_CHECKOUT_MODAL_ID }));
  }, [dispatch]);

  const showAddPaymentCardModal = useCallback(() => {
    dispatch(setActiveModal({ id: ADD_PAYMENT_CARD_MODAL_ID }));
  }, [dispatch]);

  const editPaymentMethod = (stripe, data, card) => {
    data.returnToPurchase = showPurchaseCheckoutModal;
    dispatch(editStripePaymentMethod(stripe, data, card, newSubService));
  };

  const handleAddModalSubmit = (stripe, data, card) => {
    data.returnToPurchase = showPurchaseCheckoutModal;
    data.showSuccessToast = false;
    dispatch(createStripePaymentMethod(stripe, data, card, newSubService));
  };

  const handleAddModalMount = useCallback(() => {
    /* noop */
  }, []);

  const closeModal = () => {
    dispatch(clearModalState());
  };

  const openPaymentFlow = useCallback(() => {
    if (!isSubscribed) {
      if (paymentMethods.length) {
        showPurchaseCheckoutModal();
      } else {
        showAddPaymentCardModal();
      }
    } else {
      history.push(paymentModalBtn.url);
    }
  }, [
    paymentMethods,
    history,
    isSubscribed,
    paymentModalBtn,
    showPurchaseCheckoutModal,
    showAddPaymentCardModal
  ]);

  const handleOnClick = useCallback(async () => {
    openPaymentFlow();
  }, [openPaymentFlow]);

  useEffect(() => {
    if (manualTrigger) manualTrigger.current = handleOnClick;
  }, [manualTrigger, handleOnClick]);

  return (
    <>
      {!manualTrigger && (
        <LoaderButton
          type='button'
          className={classnames({
            'btn--disabled': buttonDisabled,
            'btn--pill': get(paymentModalBtn, 'class').length === 0,
            [get(paymentModalBtn, 'class')]:
              get(paymentModalBtn, 'class').length > 0
          })}
          disabled={buttonDisabled}
          onClick={() => handleOnClick()}
          loadingIconOnly={true}
          loading={loading}
          showIcon={true}
          text={paymentModalBtn.title}
        />
      )}
      <ModalConductor
        activeModal={modalState}
        setActiveModal={(data) => dispatch(setActiveModal(data))}
        hideCloseBtn={true}
        onClose={closeModal}
        className={classnames({ hidden: hideModals })}
        modals={[
          {
            id: PURCHASE_CHECKOUT_MODAL_ID,
            node: (
              <PurchaseCheckoutModal
                handleClose={closeModal}
                paymentMethod={paymentMethods[0]}
                creatorToolName={subName}
                creatorToolId={currentToolId}
                priceOption={priceOption}
                postPaymentAction={postPaymentAction}
                metadata={purchaseMetaData}
                headerCopy={
                  <HeaderCopy subName={subName} priceOption={priceOption} />
                }
              />
            )
          },
          {
            id: EDIT_PAYMENT_CARD_MODAL_ID,
            node: (
              <Elements stripe={stripePromise}>
                <PaymentCardModal
                  handleClose={showPurchaseCheckoutModal}
                  headerActionText='Edit'
                  paymentMethodToEdit={{
                    paymentMethod: paymentMethods[0],
                    index: 0
                  }}
                  handleOnSubmit={editPaymentMethod}
                  headerCopy={
                    <HeaderCopy subName={subName} priceOption={priceOption} />
                  }
                />
              </Elements>
            )
          },
          {
            id: ADD_PAYMENT_CARD_MODAL_ID,
            node: (
              <Elements stripe={stripePromise}>
                <PaymentCardModal
                  handleClose={closeModal}
                  headerActionText='Add'
                  paymentMethodToEdit={{
                    paymentMethod: paymentMethods[0],
                    index: 0
                  }}
                  handleOnSubmit={handleAddModalSubmit}
                  handleMount={handleAddModalMount}
                  headerCopy={
                    <HeaderCopy subName={subName} priceOption={priceOption} />
                  }
                  hideCardFormTitle={true}
                />
              </Elements>
            )
          },
          {
            id: PURCHASE_CHECKOUT_CONFIRMATION_MODAL_ID,
            node: (
              <OrderCompleteModal
                handleClose={closeModal}
                paymentMethod={paymentMethods[0]}
                subscriptionData={subscriptionData}
                priceOption={priceOption}
              />
            )
          },
          {
            id: ADD_PAYMENT_CARD_RESUBSCRIBE_MODAL_ID,
            node: (
              <Elements stripe={stripePromise}>
                <PaymentCardModal
                  handleClose={closeModal}
                  headerActionText='Add'
                  handleOnSubmit={createStripePaymentMethod}
                  headerCopy=''
                  hideCardFormTitle={false}
                />
                ;
              </Elements>
            )
          }
        ]}
      />
    </>
  );
};

PaymentProcessing.propTypes = {
  descriptiveLogTag: string,
  buttonDisabled: bool,
  subscriptionData: object.isRequired,
  isSubscribed: bool.isRequired,
  priceOption: object,
  paymentModalBtn: object,
  purchaseMetaData: object,
  loading: bool,
  postPaymentAction: func,
  manualTrigger: object
};

PaymentProcessing.defaultProps = {
  descriptiveLogTag: undefined,
  purchaseMetaData: undefined,
  buttonDisabled: false,
  paymentModalBtn: {
    url: undefined,
    title: undefined
  },
  priceOption: {
    reoccuring: true,
    currency: 'USD',
    price: '050'
  },
  loading: false,
  postPaymentAction: undefined,
  /*
   * Pass in a ref (i.e. from `useRef` to trigger the modal flow manually. If
   * this is not provided, a button that triggers the flow with be rendered instead.
   *
   * Reference: https://tinyurl.com/y57hf353
   * */
  manualTrigger: undefined
};

export default PaymentProcessing;
