import { useParams } from 'react-router-dom';

import AppSettingsTemplate from './AppSettingsTemplate';
import GeneralTabTemplate from './GeneralTabTemplate';
import { APP_SETTINGS_TABS } from '../../constants/AppSettingsTabs';
import NotFoundPage from '../NotFoundPage';
import IntegrationConfig from '../IntegrationPage/IntegrationConfig';
import content from './content.json';

/**
 * Renders the settings for apps configured in content.json
 */
const AppSettingsPage = () => {
  const { app } = useParams();

  const pageContent = content.find(
    (appSettings) => appSettings.appSlug === app
  );
  if (!pageContent) {
    return <NotFoundPage />;
  }

  const { header, tabs, appName } = pageContent.content;
  const { displayName, body } = IntegrationConfig[appName];
  const [generalTab] = tabs.filter(
    (tab) => tab.id === APP_SETTINGS_TABS.general
  );
  const [helpAndSupportTab] = tabs.filter(
    (tab) => tab.id === APP_SETTINGS_TABS.helpAndSupport
  );
  const tabsContent = [
    {
      title: generalTab.title.toLowerCase(),
      render: (
        <GeneralTabTemplate
          cta={generalTab.cta}
          management={generalTab.management}
          onLogEvent={() => {}}
        />
      )
    },
    {
      title: helpAndSupportTab.title.toLowerCase(),
      render: <div />
    }
  ];

  return (
    <AppSettingsTemplate
      content={{
        displayName,
        body,
        header,
        tabs: tabsContent
      }}
    />
  );
};

export default AppSettingsPage;
