import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useMemo
} from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useHeaderData } from 'hooks';
import {
  MobileNav,
  SideNav,
  bpProps,
  ModalConductor
} from '@teespring/ts-components';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import { node, string, bool } from 'prop-types';
import TermsOfServiceModal, {
  TERMS_OF_SERVICE_MODAL_ID
} from 'components/TermsOfServiceModal';
import { clearModalState, setActiveModal } from 'redux/actions';
import { LAUNCHER_URL } from '../../constants';
import './MainLayout.scss';
import { API_HOST } from '../../constants';

const VOID_TOS_PATHS = [
  '/dashboard/settings',
  '/dashboard/payouts',
  '/purchases',
  '/purchases/purchase-history',
  '/purchases/subscriptions',
  '/purchases/payment-method',
  '/404'
];

const MainLayout = ({
  children,
  className,
  mobileNavTitle,
  collapsedNav,
  sidePane,
  useWhiteBackground,
  hideNav,
  hideMobileNav,
  hideCreateButton
}) => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  const [dimensions, setDimensions] = useState({});
  const locationPath = useLocation().pathname;
  const { header } = useHeaderData();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearModalState());
  }, [dispatch, locationPath]);

  useEffect(() => {
    const createButton = document.createElement('a');
    createButton.setAttribute('id', 'mobile-nav-create-product');
    createButton.setAttribute('class', 'spr_create_button');
    createButton.setAttribute('href', 'https://teespring.com/design-launcher');

    const penIconElement = document.createElement('img');
    penIconElement.src = require('../../assets/penIcon.png');
    penIconElement.setAttribute('class', 'spr_pen_style');
    penIconElement.setAttribute('alt', 'penIcon');

    createButton.appendChild(penIconElement);

    const createProductText = document.createElement('span');
    createProductText.innerHTML = 'Create Product';

    createButton.appendChild(createProductText);
    const el = document.getElementsByClassName('spr_mobilenav__header').item(0);

    const myEle = document.getElementById('mobile-nav-create-product');

    if (el && !myEle) {
      el.appendChild(createButton);
    }
  });

  //Show terms of service modal if not accepted
  const shouldShowTOS = useMemo(() => {
    const { tosAgreement } = get(header, 'user');
    if (VOID_TOS_PATHS.includes(locationPath)) return false;
    else return !tosAgreement;
  }, [header, locationPath]);

  useEffect(() => {
    if (shouldShowTOS) {
      dispatch(setActiveModal({ id: TERMS_OF_SERVICE_MODAL_ID }));
    } else {
      dispatch(clearModalState());
    }
  }, [shouldShowTOS, dispatch]);

  const { modal: modalState } = useSelector((state) => state.modal);
  const { query } = useQuery();
  const tosRedirectUrl = query.get('tos_redirect');
  const tosModalConductor = () => {
    const closeModal = () => {
      if (tosRedirectUrl) {
        window.location.href = tosRedirectUrl;
        // Don't programmatically close the modal here and let the redirect away close the modal
      } else {
        dispatch(clearModalState());
      }
    };
    const standardModals = [
      {
        id: TERMS_OF_SERVICE_MODAL_ID,
        node: <TermsOfServiceModal handleClose={closeModal} />
      }
    ];
    return (
      <ModalConductor
        activeModal={modalState}
        setActiveModal={(data) => dispatch(setActiveModal(data))}
        hideCloseBtn={true}
        onClose={closeModal}
        modals={standardModals}
      />
    );
  };

  function useDimensions() {
    const ref = useRef(null);
    useLayoutEffect(() => {
      setDimensions(ref?.current?.getBoundingClientRect() ?? 0);
    }, [ref]);

    return [ref, dimensions];
  }

  const [sidebarRef] = useDimensions();

  const handleAdd = () => {
    window.location.assign(LAUNCHER_URL);
  };

  const items = get(header, 'items');

  //Redirect to /verify-email if the user is new (registered after cutoff) and not verified
  const EMAIL_VERIFICATION_CUTOFF_DATE = '2025-02-14T00:00:00';
  useEffect(() => {
    //Redirect to /verify-email if not verified
    //This logic is not inside a useEffect to ensure the user is redirected
    //immediately without seeing /overview or any other page.
    const isVerified = header?.user?.verified;
    const createdAt = header?.user?.createdAt;
    if (
      isVerified === false &&
      createdAt &&
      new Date(createdAt) > new Date(EMAIL_VERIFICATION_CUTOFF_DATE)
    ) {
      window.location.href = `${API_HOST}/verify-email`;
      return null;
    }
  });

  return (
    <div className={`main ${className}`}>
      {tosModalConductor()}
      {!hideNav && bpIsGT('mobileLg') ? (
        <div className='main__sidebar' ref={sidebarRef}>
          <SideNav
            items={items}
            user={get(header, 'user')}
            collapsed={collapsedNav}
            activeRoute={locationPath}
            version={2}
            logEvent={() => {}}
          />
          {sidePane}
        </div>
      ) : (
        <div
          className={`spr_mobilenav__container ${
            hideCreateButton && 'hide_create_button'
          }`}
          style={{ display: hideMobileNav ? 'none' : 'initial' }}
        >
          <MobileNav
            activeRoute={locationPath}
            items={get(header, 'items')}
            logEvent={() => {}}
            onAdd={handleAdd}
            pageTitle={mobileNavTitle}
            showMobileNavShelf={false}
            user={get(header, 'user')}
            version={2}
          />
        </div>
      )}

      <div
        className={`main__content ${
          useWhiteBackground
            ? 'main__content__bg__white'
            : 'main__content__default__bg'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: node.isRequired,
  className: string,
  mobileNavTitle: string,
  collapsedNav: bool,
  sidePane: node,
  useWhiteBackground: bool,
  hideNav: bool,
  hideMobileNav: bool,
  hideCreateButton: bool
};

MainLayout.defaultProps = {
  className: '',
  mobileNavTitle: '',
  collapsedNav: false,
  sidePane: null,
  useWhiteBackground: false,
  hideNav: false,
  hideMobileNav: false,
  hideCreateButton: false
};

export default MainLayout;
