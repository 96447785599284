import useIntegrations from 'hooks/useIntegrations';
import IntegrationCards from './IntegrationCards';
import IntegrationsCarousel from './carousel/index';
import { SellInOtherPlatformsGuide } from './SellInOtherPlatformsGuide';

const IntegrationsMain = () => {
  const { integrations, steps } = useIntegrations();
  return (
    <div className='integrations_page'>
      <IntegrationsCarousel steps={steps} />
      <div className='integrations_page_sections'>
        <IntegrationCards socials={integrations} />
        <SellInOtherPlatformsGuide />
      </div>
    </div>
  );
};

export default IntegrationsMain;
