import { bpProps } from '@teespring/ts-components';
import ModalTemplate from 'components/ModalTemplate';
import { func, object, string } from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import confetti from 'canvas-confetti';
import './OrderCompleteModal.scss';

export const PURCHASE_CHECKOUT_CONFIRMATION_MODAL_ID =
  'purchase-checkout-confirmation-modal';

export const OrderCompleteModal = ({
  subscriptionData,
  priceOption,
  handleClose,
  descriptiveLogTag
}) => {
  const { bpIsGT, bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));
  const { name } = useSelector((state) => state.header.user);
  const storeId = useSelector((state) => state?.store?.id ?? 0);
  const history = useHistory();
  const {
    name: toolName,
    description,
    id,
    metaData: { data: { icon, nextSteps, purchaseCompleteURL } = {} } = {}
  } = subscriptionData;

  const isMobile = bpIsLT('mobileLg');

  const congratsRef = useRef();
  const launchConfetti = async () => {
    const confettiCanvas = document.createElement('canvas');
    confettiCanvas.style.position = 'absolute';
    confettiCanvas.style.top = '0';
    confettiCanvas.style.right = '0px';
    confettiCanvas.style.left = '0px';
    confettiCanvas.style.width = '100%';
    confettiCanvas.style.height = isMobile ? '300px' : '400px';

    const confettiEl = congratsRef?.current;
    confettiEl.appendChild(confettiCanvas);

    const confettiLauncher = confetti.create(confettiCanvas, {
      disableForReducedMotion: true,
      resize: true,
      useWorker: true
    });

    confettiLauncher({
      startVelocity: 20,
      particleCount: 200,
      spread: 360,
      gravity: 0.85
    });

    setTimeout(() => {
      confettiCanvas.style.display = 'none';
      confettiLauncher.reset();
    }, 4000);
  };

  const purchaseCompleteURLInterpolated =
    storeId && purchaseCompleteURL
      ? purchaseCompleteURL
          .replace(/{{storeId}}/g, storeId)
          .replace(/{{toolId}}/g, id)
      : null;

  return (
    <ModalTemplate
      modalOnLoad={launchConfetti}
      modalTitle='Order complete'
      modalTitleAlign={bpIsGT('mobileLg') ? 'center' : 'left'}
      modalBtnText='Continue'
      modalBtnClass='wide'
      modalClose={handleClose}
      modalHideCancelButton={true}
      modalBtnAction={() => {
        history.push(
          purchaseCompleteURLInterpolated ?? '/purchases/subscriptions'
        );
        handleClose();
      }}
      modalContent={
        <div className='purchase_confirmation__wrapper' ref={congratsRef}>
          <div className='purchase_confirmation__heading'>
            Thank you {name}!
          </div>

          <div className='purchase_confirmation__content'>
            You will receive a confirmation email for your purchase shortly. You
            can manage your subscriptions and review purchase history in the{' '}
            <Link to='/purchases/subscriptions' className='typ--link'>
              Payments section
            </Link>{' '}
            of your account at any time.
          </div>

          <div className='purchase_confirmation__summary_heading'>Summary</div>
          <hr />
          <div className='summary__wrapper'>
            <div className='summary__logo'>
              <img src={icon?.url} alt={icon?.alt} />
            </div>
            <div className='summary__metawrapper'>
              <div className='summary__title'>{toolName}</div>

              <div className='summary__description'>{description}</div>
            </div>
          </div>
          <div className='summary__followup_message'>{nextSteps}</div>
        </div>
      }
    />
  );
};

OrderCompleteModal.propTypes = {
  subscriptionData: object.isRequired,
  priceOption: object.isRequired,
  handleClose: func.isRequired,
  descriptiveLogTag: string
};

OrderCompleteModal.defaultProps = {
  descriptiveLogTag: undefined
};

export default OrderCompleteModal;
