import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useIntegrations, { getIntegrationData } from 'hooks/useIntegrations';
import { useDispatch, useSelector } from 'react-redux';
import IntegrationsConfig from './config/IntegrationsConfig';
import IntegrationsPageSlider from './IntegrationsPageSlider';
import IntegrationsPageTitle from './IntegrationsPageTitle';
import IntegrationsPageSubtitle from './IntegrationsSubtitle';
import IntegrationsPageContent from './IntegrationsPageContent';
import './IntegrationsPage.scss';
import {
  userIntegrated,
  userNotIntegrated
} from '../../../redux/actions/integratedConnection';

export const IntegrationsPageTemplate = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { email } = useSelector((state) => state.header.user);
  const { integrations: socialIntegrations } = useIntegrations();
  const logEventStub = useCallback(() => {}, []);
  const newLinktreeExperienceEnabled =
    process.env.REACT_APP_NEW_LINKTREE_EXPERIENCE_ENABLED === 'true' ||
    email.endsWith('@linktr.ee') ||
    email.endsWith('@spri.ng') ||
    email.endsWith('@amaze.co');

  let { integrationSlug } = useParams();
  if (integrationSlug === 'linktree' && !newLinktreeExperienceEnabled) {
    integrationSlug = 'linktreeLegacy';
  }
  const integrations = IntegrationsConfig(user);
  const integration = socialIntegrations.find(
    (integr) => integr.name === integrations[integrationSlug]?.title
  );

  const integrationData = integrations[integrationSlug];

  useEffect(() => {
    getIntegrationData().then((status) => {
      if (status?.[integrationSlug]) {
        dispatch(userIntegrated());
      } else {
        dispatch(userNotIntegrated());
      }
    });
  }, [dispatch, integrationSlug]);

  return (
    <div className='integrations_page_container'>
      <IntegrationsPageTitle
        title={integrationData?.title}
        iconUrl={integration?.logo}
      />
      <IntegrationsPageSubtitle subtitle={integrationData?.subtitle} />
      <IntegrationsPageSlider imageUrlArray={integrationData?.bannerImgArray} />
      <IntegrationsPageContent
        integration={integrationData}
        logEvent={logEventStub}
      />
    </div>
  );
};

export default IntegrationsPageTemplate;
