// https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const cookieSetValue = (name, value, options) =>
  cookies.set(name, value, { ...options });
export const cookieGetValue = (key) => cookies.get(key);
export const cookieRemoveValue = (key) => cookies.remove(key, { path: '/' });

const useCookie = () => {
  return {
    setValue: cookieSetValue,
    getValue: cookieGetValue,
    removeValue: cookieRemoveValue
  };
};

export default useCookie;
