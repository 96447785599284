import DatePicker from 'react-datepicker';
import { ModalId } from '../../types/analytics';
import { format } from 'date-fns';
import { ModalConductor } from '@teespring/ts-components';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangeModal.scss';

type DateRangeModalProps = {
  tempRange: { startDate: Date; endDate: Date };
  setTempRange: (range: { startDate: Date; endDate: Date }) => void;
  handleApply: () => void;
  handleCancel: () => void;
  activeModal: { id: string };
  setActiveModal: (modal: { id: ModalId }) => void;
};

type CustomHeaderProps = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  nextMonthButtonDisabled: boolean;
  prevMonthButtonDisabled: boolean;
};

const renderCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled
}: CustomHeaderProps) => (
  <div className='custom-header'>
    <button
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
      className='prev'
    >
      <KeyboardArrowLeftIcon />
    </button>
    <span>{format(date, 'MMM yyyy')}</span>
    <button
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
      className='next'
    >
      <KeyboardArrowRightIcon />
    </button>
  </div>
);

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  tempRange,
  setTempRange,
  handleApply,
  handleCancel,
  activeModal,
  setActiveModal
}) => {
  return (
    <ModalConductor
      activeModal={activeModal}
      setActiveModal={setActiveModal}
      hideCloseBtn={true}
      modals={[
        {
          id: 'datepicker-calendar',
          node: (
            <>
              <div className='calendar-popup'>
                <div className='calendar-container'>
                  <div>
                    <div className='calendar-header'>
                      <span>
                        <span className='calendar-label'>Start: </span>
                        {format(tempRange.startDate, 'MMM d, yyyy')}
                      </span>
                    </div>
                    <DatePicker
                      selected={tempRange.startDate}
                      onChange={(date: Date | null) =>
                        setTempRange({
                          ...tempRange,
                          startDate: date ?? new Date()
                        })
                      }
                      selectsStart
                      startDate={tempRange.startDate}
                      endDate={tempRange.endDate}
                      maxDate={new Date()}
                      renderCustomHeader={renderCustomHeader}
                      className='monthRange'
                      inline
                    />
                  </div>
                  <div>
                    <div className='calendar-header'>
                      <span>
                        <span className='calendar-label'>End: </span>
                        {format(tempRange.endDate, 'MMM d, yyyy')}
                      </span>
                    </div>
                    <DatePicker
                      selected={tempRange.endDate}
                      onChange={(date: Date | null) =>
                        setTempRange({
                          ...tempRange,
                          endDate: date ?? new Date()
                        })
                      }
                      selectsEnd
                      startDate={tempRange.startDate}
                      endDate={tempRange.endDate}
                      minDate={tempRange.startDate}
                      maxDate={new Date()}
                      renderCustomHeader={renderCustomHeader}
                      className='monthRange'
                      inline
                    />
                  </div>
                </div>
                <div className='calendar-actions'>
                  <button
                    className='button cancel-button'
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button className='button apply-button' onClick={handleApply}>
                    Apply
                  </button>
                </div>
              </div>
            </>
          )
        }
      ]}
    />
  );
};

export default DateRangeModal;
