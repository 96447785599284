import { Button } from '@mui/material';
import { func } from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import './index.scss';

const HubspotSurvey = (props) => {
  return (
    <div className='pop-up-shop-hubspot-survey'>
      <div className='pop-up-shop-hubspot-survey__content'>
        <HubspotForm
          portalId='23573381'
          formId='a3aa2967-941d-4545-aa7d-138f8553ad09'
          onSubmit={() => props.onSubmit?.()}
          region='na1'
        />
        <Button
          className='pop-up-shop-hubspot-survey__ignore'
          onClick={props.onIgnore}
          size='small'
          variant='text'
        >
          Don't show me this again
        </Button>
      </div>
    </div>
  );
};

HubspotSurvey.propTypes = {
  onIgnore: func,
  onSubmit: func
};

export { HubspotSurvey };
