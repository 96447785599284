/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { string } from 'prop-types';
import './IntegrationItem.scss';

const IntegrationItem = ({ url, image, newTarget }) => {
  const gotoIntegration = async (target) => {
    window.open(url, target);
  };

  return (
    <div className='integration-item'>
      <img src={image} alt='' onClick={() => gotoIntegration(newTarget)} />
    </div>
  );
};

IntegrationItem.defaultProps = {
  url: '',
  image: '',
  newTarget: '_blank'
};

IntegrationItem.propTypes = {
  url: string,
  image: string,
  newTarget: string
};

export default IntegrationItem;
