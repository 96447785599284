import { func, node, object, string } from 'prop-types';
import { bpProps } from '@teespring/ts-components';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  setActiveModal,
  createPaymentIntent,
  createSubscription
} from 'redux/actions';
import ModalTemplate from 'components/ModalTemplate';
import './PurchaseCheckoutModal.scss';
import { EDIT_PAYMENT_CARD_MODAL_ID } from 'components/PaymentProcessing/PaymentCardModal';
import { useCookie } from 'hooks';

export const PURCHASE_CHECKOUT_MODAL_ID = 'purchase-checkout-modal';

export const PurchaseCheckoutModal = ({
  handleClose,
  paymentMethod,
  creatorToolName,
  creatorToolId,
  priceOption,
  metadata,
  headerCopy,
  postPaymentAction
}) => {
  const { newSubService } = useFlags();
  const { getValue } = useCookie();

  const dispatch = useDispatch();
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));
  const { isEditingPaymentMethods } = useSelector(
    (state) => state.subscriptions
  );
  const { isConfirmingPurchase } = useSelector((state) => state.subscriptions);

  // Stripe customer Id
  const { customerId } = useSelector((state) => state.subscriptions);

  const { userId } = useSelector((state) => state.user);
  const storeSlug = useSelector((state) => get(state, 'store.slug', ''));
  const storeId = useSelector((state) => get(state, 'store.id', ''));
  const storeName = useSelector((state) => get(state, 'store.name', ''));

  const changeModalState = () => {
    dispatch(setActiveModal({ id: EDIT_PAYMENT_CARD_MODAL_ID }));
  };

  const handleCreatePayment = () => {
    if (newSubService) {
      dispatch(
        createSubscription({
          creatorToolName,
          creatorToolId,
          customerId,
          paymentMethod,
          metadata,
          userId,
          storeSlug,
          storeId,
          storeName
        })
      );
    } else {
      dispatch(
        createPaymentIntent({
          priceId: priceOption.id,
          creatorToolName,
          creatorToolId,
          metadata,
          postPaymentAction,
          iterableCampaignId: getValue('iterableCampaignId'),
          iterableTemplateId: getValue('iterableTemplateId')
        })
      );
    }
  };

  return (
    <ModalTemplate
      modalTitle='Confirm purchase'
      modalTitleAlign={bpIsGT('mobileLg') ? 'center' : 'left'}
      modalBtnText='Purchase'
      modalBtnLoading={isConfirmingPurchase || isEditingPaymentMethods}
      modalBtnDisabled={isConfirmingPurchase}
      modalBtnAction={handleCreatePayment}
      modalBtnClass='purchase_checkout_modal__action_button'
      modalClose={handleClose}
      modalContent={
        <div className='purchase_checkout_modal__container'>
          {headerCopy}
          <div className='purchase_checkout_modal__card_info mt2'>
            <div className='purchase_checkout_modal__card_data'>
              <div className='purchase_checkout_modal__card_logo_container'>
                <img
                  className='purchase_checkout_modal__card_logo'
                  src={paymentMethod.logo}
                  alt='Card logo'
                />
              </div>
              <p className='ml1 mr1 mb0'>
                {paymentMethod.brand}{' '}
                <span>****&nbsp;{paymentMethod.last4}</span>
              </p>
            </div>
            <button type='button' onClick={changeModalState}>
              Change
            </button>
          </div>
        </div>
      }
    />
  );
};

PurchaseCheckoutModal.propTypes = {
  headerCopy: node.isRequired,
  handleClose: func.isRequired,
  paymentMethod: object.isRequired,
  creatorToolName: string.isRequired,
  creatorToolId: string.isRequired,
  priceOption: object.isRequired,
  metadata: object,
  postPaymentAction: func
};

PurchaseCheckoutModal.defaultProps = {
  metadata: undefined,
  postPaymentAction: undefined
};

export default PurchaseCheckoutModal;
