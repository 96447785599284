import {
  FETCHING_SERVICE_INFO_FAILED,
  IS_CHECKING_OUT,
  IS_FETCHING_SERVICE_INFO,
  IS_FETCHING_SERVICE_INFO_DONE,
  IS_FETCHING_USER_SUBSCRIPTIONS,
  IS_FETCHING_USER_SUBSCRIPTIONS_DONE,
  RECEIVE_SUBSCRIPTION_SERVICES,
  RECEIVE_USER_SUBSCRIPTIONS,
  REDIRECT_TO_STRIPE_CHECKOUT,
  UPDATE_SUBSCRIPTION_STATUS,
  SET_PAYMENT_METHODS,
  IS_FETCHING_PAYMENT_METHODS,
  IS_EDITING_PAYMENT_METHODS,
  IS_CONFIRMING_PURCHASE,
  SET_CUSTOMER_ID,
  PAYMENT_METHOD_HAS_SUBSCRIPTION,
  RESUBSCRIBE_SUBSCRIPTION_ID
} from 'redux/actions';

const INITIAL_STATE = {
  active: {},
  fetchingServiceInfoFailed: false,
  inactive: {},
  isCheckingOut: false,
  isFetchingServiceInfo: false,
  isFetchingUserSubscriptions: false,
  services: {},
  stripeSessionData: {},
  paymentMethods: [],
  isFetchingPaymentMethods: false,
  isEditingPaymentMethods: false,
  isConfirmingPurchase: false
};

/**
 * Get Subscription data from Spring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
const subscriptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethodData
      };
    case IS_FETCHING_PAYMENT_METHODS:
      return { ...state, isFetchingPaymentMethods: action.isFetching };
    case IS_EDITING_PAYMENT_METHODS:
      return { ...state, isEditingPaymentMethods: action.isEditing };
    case FETCHING_SERVICE_INFO_FAILED:
      return { ...state, fetchingServiceInfoFailed: true };
    case IS_CHECKING_OUT:
      return { ...state, isCheckingOut: action.isCheckingOut };
    case IS_FETCHING_SERVICE_INFO:
      return {
        ...state,
        fetchingServiceInfoFailed: false,
        isFetchingServiceInfo: true
      };
    case IS_FETCHING_SERVICE_INFO_DONE:
      return { ...state, isFetchingServiceInfo: false };
    case IS_FETCHING_USER_SUBSCRIPTIONS:
      return { ...state, isFetchingUserSubscriptions: true };
    case IS_FETCHING_USER_SUBSCRIPTIONS_DONE:
      return { ...state, isFetchingUserSubscriptions: false };
    case RECEIVE_SUBSCRIPTION_SERVICES:
      return {
        ...state,
        services: {
          ...state.services,
          [action.serviceId]: action.data
        }
      };
    case RECEIVE_USER_SUBSCRIPTIONS:
      return {
        ...state,
        active: { ...state?.active, ...action.data.active },
        inactive: { ...state?.inactive, ...action.data.inactive }
      };
    case REDIRECT_TO_STRIPE_CHECKOUT:
      return { ...state, stripeSessionData: action.data };
    case UPDATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        active: {
          ...state.active,
          [action.data.subscriptionId]: {
            ...state.active[action.data.subscriptionId],
            purchase_status: action.data.status
          }
        }
      };
    case IS_CONFIRMING_PURCHASE:
      return {
        ...state,
        isConfirmingPurchase: action.isConfirmingPurchase
      };
    case SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.customerId
      };
    case PAYMENT_METHOD_HAS_SUBSCRIPTION:
      return {
        ...state,
        paymentMethodHasSubscription: action.paymentMethodHasSubscription
      };
    case RESUBSCRIBE_SUBSCRIPTION_ID:
      return {
        ...state,
        paymentVendorSubscriptionId: action.paymentVendorSubscriptionId
      };
    default:
      return state;
  }
};

export default subscriptionsReducer;
