import classnames from 'classnames';
import { useState } from 'react';
import {
  LoaderButton,
  IconButton,
  ModalConductor
} from '@teespring/ts-components';
import { array, bool, number, string, oneOfType } from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { API_HOST } from '../../../constants';
import { generateCheckoutUrl } from '../../../routes/Samples/utils';
import SamplesMobileCheckoutModal from './SamplesMobileCheckoutModal';
import './SamplesFooter.scss';

const INITIAL_MODAL_STATE = { id: null, mProps: {} };

const SamplesFooter = ({
  totalItems,
  isMobile,
  cartItems,
  variations,
  currency,
  region
}) => {
  const [activeModal, setActiveModal] = useState(INITIAL_MODAL_STATE);

  const handleCheckout = () => {
    ReactPixel.track('ProceedToCheckout', { variations });
    const url = generateCheckoutUrl(variations, cartItems, currency);
    window.location.assign(`${API_HOST}${url}`);
  };

  const closeModal = () => setActiveModal(INITIAL_MODAL_STATE);

  return (
    <>
      <div className='samples__footer'>
        <div className='samples__footer__item__count'>Items: {totalItems}</div>

        {isMobile ? (
          <IconButton
            className={classnames('btn samples__footer__mobile__checkout', {
              'is-disabled': cartItems?.length === 0
            })}
            disabled={cartItems?.length === 0}
            iconName='ArrowRight'
            text='Continue'
            onClick={() => setActiveModal({ id: 'samples__mobile__checkout' })}
          />
        ) : (
          <LoaderButton
            className={classnames('samples__footer__checkout__btn', {
              'is-disabled': cartItems?.length === 0
            })}
            disabled={cartItems?.length === 0}
            type='button'
            onClick={handleCheckout}
            text='Continue to checkout'
            loadingText='Proceeding...'
          />
        )}
      </div>
      <ModalConductor
        activeModal={activeModal}
        setActiveModal={closeModal}
        hideCloseBtn={true}
        modals={[
          {
            id: 'samples__mobile__checkout',
            node: (
              <SamplesMobileCheckoutModal
                cartItems={cartItems}
                region={region}
                handleCheckout={handleCheckout}
                handleCancel={closeModal}
              />
            )
          }
        ]}
      />
    </>
  );
};

SamplesFooter.propTypes = {
  totalItems: oneOfType([string, number]).isRequired,
  isMobile: bool.isRequired,
  cartItems: array.isRequired,
  variations: array.isRequired,
  currency: string.isRequired,
  region: string.isRequired
};

export default SamplesFooter;
