import { useState } from 'react';
import { LoaderButton } from '@teespring/ts-components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { string } from 'prop-types';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { API_HOST } from '../../../constants';
import './EmailVerifyBar.scss';

const modalContent = (email = 'your email address') => ({
  unsent: {
    header: 'Verify your email address',
    firstBody: `A verification link has been sent to ${email}. Check your email for your verification link. Note this link is only valid for the next 24 hours.`,
    buttonText: 'Resend verification email',
    class: ''
  },
  sent: {
    header: 'Verify your email address',
    firstBody: `A verification link has been sent to ${email}. Check your email for your verification link. Note this link is only valid for the next 24 hours.`,
    buttonText: 'Sent, verification pending',
    class: 'success'
  },
  failed: {
    header: 'Verification email failed to send',
    firstBody:
      'We were unable to send the email at this time. Please try again later.',
    buttonText: 'Resend verification email',
    class: 'fail'
  }
});

const EmailVerifyBar = ({ email }) => {
  const [sendEmailStatus, setSendEmailStatus] = useState('unsent');
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const getModalContent = modalContent(email);

  const sendEmailHandler = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_HOST}/resend_verification`,
        formatAxiosRequestHeaders(token)
      );
      setLoading(false);
      if (response?.data?.message === 'Sent!') {
        setSendEmailStatus('sent');
      } else {
        setSendEmailStatus('failed');
      }
    } catch (e) {
      setLoading(false);
      setSendEmailStatus('failed');
      throw new Error(`An error occurred: ${e}`);
    }
  };

  return (
    <div
      className={`email-verify-bar__content ${getModalContent[sendEmailStatus].class}`}
    >
      <div className='email-verify-bar__body'>
        <h1>{getModalContent[sendEmailStatus].header}</h1>
        <p className='email-verify-bar__text'>
          {getModalContent[sendEmailStatus].firstBody}
        </p>
      </div>
      <div className='break-box' />
      <div className='email-verify-bar__button'>
        <LoaderButton
          type='button'
          className='btn'
          onClick={sendEmailHandler}
          disabled={loading}
          text={`${
            loading ? 'Sending...' : getModalContent[sendEmailStatus].buttonText
          }`}
        />
      </div>
    </div>
  );
};

EmailVerifyBar.defaultProps = {
  email: ''
};

EmailVerifyBar.propTypes = {
  email: string
};

export default EmailVerifyBar;
