import {
  RECEIVE_ANALYTICS_OVERVIEW_DATA,
  SET_DEFAULT_ANALYTICS_OVERVIEW_DATA,
  SET_FETCHING_ANALYTICS_OVERVIEW
} from 'redux/actions';

export const INITIAL_STATE = {
  productsOrdered: '',
  profit: '$',
  charityProfit: '$',
  toBePaid: '$',
  chargedProfit: '$',
  pendingProfit: '$',
  isFetching: false
};

const analyticsOverviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_ANALYTICS_OVERVIEW_DATA: {
      return {
        ...state,
        ...action.analyticsOverview,
        isFetching: action.isFetching
      };
    }
    case SET_FETCHING_ANALYTICS_OVERVIEW: {
      return {
        ...state,
        isFetching: action.isFetching
      };
    }
    case SET_DEFAULT_ANALYTICS_OVERVIEW_DATA: {
      return {
        ...INITIAL_STATE,
        isFetching: action.isFetching
      };
    }
    default:
      return state;
  }
};

export default analyticsOverviewReducer;
