import React from 'react';
import { array, string } from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import parse from 'html-react-parser';
import './AppList.scss';

const AppsList = ({ appsCategoryName, apps }) => {
  const isAbsoluteURL = ({ url }) => {
    const regex = /^https?:\/\//i;
    return regex.test(url);
  };

  return isEmpty(apps) ? null : (
    <section className='app-list' title={`${appsCategoryName}-app-list`}>
      <header className='app-list__title'>
        <h2>{appsCategoryName}</h2>
      </header>
      <ul>
        {apps.map((app) => (
          <li className='app-list__item' key={app.name}>
            <div className='app-list__block'>
              <div className='item__logo'> {parse(app.logo)} </div>
              <div>
                <h3>{app.name}</h3>
                <p>{app.description}</p>
              </div>
            </div>
            <div className='app-list__block'>
              {isAbsoluteURL({ url: app.link }) ? (
                <a className='item__button' href={app.link}>
                  View
                </a>
              ) : (
                <Link className='item__button' to={app.link}>
                  View
                </Link>
              )}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

AppsList.propTypes = {
  appsCategoryName: string.isRequired,
  apps: array.isRequired
};

export default AppsList;
