/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useToaster } from '@teespring/ts-components';
import axios from 'axios';
import { useQuery } from 'hooks';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { API_HOST } from '../../../constants';

import './VerificationToast.scss';

/**
 * @description - Use this toast message to show whether our user's email address is verified - based on the verified query parameter in th url
 * @description - this must be wrapped inside the ToasterProvider component
 * @returns {object} null - React and eslint require us to return null at least even though it's not returning anything.
 */
const VerificationToast = () => {
  const token = useSelector((state) => state.user.token);
  const [resendEmail, setResendEmail] = useState({
    success: false,
    message: ''
  });
  const [hasVerified, setHasVerified] = useState(null);
  const { query } = useQuery();
  const { addToast } = useToaster();

  const resendEmailHandler = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_HOST}/resend_verification`,
        formatAxiosRequestHeaders(token)
      );
      setResendEmail({
        success: response?.data?.success,
        message: response?.data?.message
      });
    } catch (e) {}
  }, [token]);

  const checkIfUserIsVerified = () => {
    const verified = query.get('verified');
    setHasVerified(Boolean(verified) && verified);
  };

  const addToastHandler = () => {
    if (hasVerified) {
      const verified = hasVerified === 'true';
      const header = verified
        ? 'Email successfully verified'
        : 'Email verification link has expired';
      const showResendEmailButton = !resendEmail.success && !verified;
      addToast(
        verified ? 'success' : 'danger',
        header,
        verified ? (
          <div data-testid='success-toast-content'>
            Thank you for confirming your email address.
          </div>
        ) : (
          <div data-testid='danger-toast-content'>
            {resendEmail.success ? (
              resendEmail.message
            ) : (
              <>
                <div>Click to resend a new one to your inbox.</div>
                <div>Please note this link will expire after 24 hours</div>
              </>
            )}
          </div>
        ),
        {
          timeout: 30000,
          action: showResendEmailButton && {
            label: 'Resend Email',
            callback: resendEmailHandler
          },
          position: 'top'
        }
      );
    }
  };

  useEffect(checkIfUserIsVerified, [query]);
  useEffect(addToastHandler, [
    resendEmail.success,
    resendEmail.message,
    hasVerified,
    resendEmailHandler
  ]);
  return null;
};

export default VerificationToast;
