import { PRODUCTS_ERROR, RECEIVE_PRODUCTS } from '../actions/products';

const INITIAL_STATE = {
  products: {},
  error: null
};

/**
 * Get Store data from teespring
 * @param  {Object} state      Redux State
 * @param  {Object} action     Action data
 * @return {Object}            Returns the state
 */
const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: action.productsData
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        error: action.err
      };
    default:
      return state;
  }
};

export default productsReducer;
