import { ADD_TOAST, REMOVE_TOAST } from 'redux/actions';

export const INITIAL_STATE = {
  notifications: []
};

const toastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        notifications: [...state.notifications, action.toast]
      };
    case REMOVE_TOAST:
      return {
        ...state,
        notifications: action.payload
      };
    default:
      return state;
  }
};

export default toastReducer;
