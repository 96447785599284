import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { bool, func, node, object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  bpProps,
  hasValue,
  InputField,
  validatorsHF
} from '@teespring/ts-components';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import './PaymentCardModal.scss';
import ModalTemplate from 'components/ModalTemplate';

export const ADD_PAYMENT_CARD_MODAL_ID = 'add-payment-card-modal';
export const ADD_PAYMENT_CARD_RESUBSCRIBE_MODAL_ID =
  'add-payment-card-resubscribe-modal';
export const EDIT_PAYMENT_CARD_MODAL_ID = 'edit-payment-card-modal';

const PaymentCardModal = ({
  handleClose,
  headerActionText,
  handleOnSubmit,
  handleMount,
  headerCopy,
  paymentMethodToEdit,
  hideCardFormTitle
}) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();

  const initialStripeState = { error: true };
  const [stripeCardCvcState, setStripeCardCvcState] =
    useState(initialStripeState);
  const [stripeCardExpiryState, setStripeCardExpiryState] =
    useState(initialStripeState);
  const [stripeCardNumberState, setStripeCardNumberState] =
    useState(initialStripeState);
  const [stripeFormState, setStripeFormState] = useState(initialStripeState);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [name, setName] = useState('');
  const { bpIsGT, bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));

  const { formState, handleSubmit, register } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { required } = validatorsHF;
  const customNameValidator = (value) =>
    (hasValue(value) && [...value.matchAll(/\w \w/g)].length >= 1) ||
    'Your Name must include at least a First and Last Name';

  const getErrors = (fieldName) => {
    const err = get(formState.errors, `['${fieldName}'].message`);
    return err ? [err] : [];
  };

  const { isEditingPaymentMethods } = useSelector(
    (state) => state.subscriptions
  );

  const modalBtnText =
    typeof paymentMethodToEdit !== 'undefined' ? 'Save' : headerActionText;

  const onSubmit = async () => {
    if (disableSubmit) return;
    const card = bpIsGT('mobileLg')
      ? elements.getElement('card')
      : elements.getElement('cardNumber');

    await dispatch(handleOnSubmit(stripe, { name, paymentMethodToEdit }, card));
  };

  useEffect(() => handleMount(), [handleMount]);

  useEffect(() => {
    const shouldEnableSubmit = () =>
      bpIsGT('mobileLg')
        ? formState.isValid && isUndefined(stripeFormState.error)
        : formState.isValid &&
          isUndefined(stripeCardCvcState.error) &&
          isUndefined(stripeCardExpiryState.error) &&
          isUndefined(stripeCardNumberState.error);

    if (shouldEnableSubmit() && elements && stripe) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [
    bpIsGT,
    bpIsLT,
    formState,
    stripeCardCvcState,
    stripeCardExpiryState,
    stripeCardNumberState,
    stripeFormState,
    setDisableSubmit,
    elements,
    stripe
  ]);

  return (
    <ModalTemplate
      modalTitle={`${headerActionText} a payment method`}
      modalTitleAlign='left'
      modalBtnText={modalBtnText}
      modalBtnAction={onSubmit}
      modalBtnDisabled={disableSubmit || isEditingPaymentMethods}
      modalClose={handleClose}
      modalBtnLoading={isEditingPaymentMethods}
      modalContent={
        <div className='add_payment_card_modal__container'>
          {headerCopy}
          <form onSubmit={handleSubmit(onSubmit)}>
            {hideCardFormTitle ? <br /> : <p className='mt0'>Card details</p>}
            {bpIsGT('mobileLg') ? (
              <CardElement
                onChange={setStripeFormState}
                options={{
                  classes: {
                    base: 'stripe_input stripe_input__card_element'
                  }
                }}
              />
            ) : (
              <CardNumberElement
                onChange={setStripeCardNumberState}
                options={{
                  classes: {
                    base: 'stripe_input stripe_input__card_number_element'
                  },
                  placeholder: 'Card number*',
                  showIcon: true
                }}
              />
            )}
            <InputField
              defaultErrors={getErrors('name')}
              name='name'
              placeholder='Name on card'
              register={register}
              required={true}
              onKeyDown={(e) => setName(e.target.value)}
              type='text'
              validators={[customNameValidator, required]}
            />
            {bpIsLT('mobileLg') && (
              <div className='card_payment__extra_info'>
                <CardExpiryElement
                  onChange={setStripeCardExpiryState}
                  options={{
                    classes: {
                      base: 'stripe_input stripe_input__card_expiry_element'
                    }
                  }}
                />
                <CardCvcElement
                  onChange={setStripeCardCvcState}
                  options={{
                    classes: {
                      base: 'stripe_input stripe_input__card_cvc_element'
                    }
                  }}
                />
              </div>
            )}
          </form>
        </div>
      }
    />
  );
};

PaymentCardModal.propTypes = {
  handleClose: func.isRequired,
  headerActionText: string.isRequired,
  handleOnSubmit: func,
  handleMount: func,
  headerCopy: node,
  paymentMethodToEdit: object.isRequired,
  hideCardFormTitle: bool
};
PaymentCardModal.defaultProps = {
  handleOnSubmit: () => {},
  handleMount: () => {},
  headerCopy: null,
  hideCardFormTitle: false
};

export default PaymentCardModal;
