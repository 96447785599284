import React from 'react';
import { Icon, CopyToClipboard, bpProps } from '@teespring/ts-components';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './ShareTooltipContent.scss';

const ShareTooltipContent = ({ shareContent, setCopying }) => {
  const { bpIsGT } = useSelector((state) => ({ ...bpProps(state) }));

  return (
    <div className='sharetooltip__content'>
      <CopyToClipboard
        contentToCopy={shareContent}
        onCopy={() => setCopying instanceof Function && setCopying(true)}
        onConfirmationEnd={() =>
          setCopying instanceof Function && setCopying(false)
        }
        confirmationMessage={
          bpIsGT('tabletLg') ? 'Copied' : 'Copied to clipboard'
        }
        copyToggle={
          <div className='sharetooltip__title'>
            <Icon size={16} name='Link' className='mr1' />
            Copy Link
          </div>
        }
      />

      <ul className='sharetooltip__shareicons'>
        {/* <li className="icon--youtube">
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon size={ 24 } name="Youtube" />
          </a>
        </li> */}
        <li className='icon--facebook'>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareContent}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon size={16} name='Facebook' />
          </a>
        </li>
        <li className='icon--instagram'>
          <a href='' target='_blank' rel='noopener noreferrer'>
            <Icon size={24} name='Instagram' />
          </a>
        </li>
        <li className='icon--twitter'>
          <a
            href={`https://twitter.com/intent/tweet?text=${shareContent}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon size={16} name='Twitter' />
          </a>
        </li>
      </ul>
    </div>
  );
};

const { string, func } = propTypes;
ShareTooltipContent.propTypes = {
  shareContent: string.isRequired,
  setCopying: func
};

ShareTooltipContent.defaultProps = {
  setCopying: () => {}
};

export default ShareTooltipContent;
