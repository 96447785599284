import { array } from 'prop-types';
import { Card, useMediaQuery, useTheme } from '@mui/material';
import './integrations.scss';
import { getIntegrationData } from 'hooks/useIntegrations';
import { useEffect, useState } from 'react';
import IntegrationCard from './IntegrationCard';

const IntegrationCards = (props) => {
  const { socials } = props;
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));

  const integrationsCardsStyle = {
    borderRadius: '16px',
    maxWidth: 'calc(920px)',
    width: '100%',
    margin: tablet ? '0px auto' : '40px auto',
    background: tablet ? 'transparent' : '#FFFFFF',
    padding: '32px 24px 16px',
    display: 'flex',
    flexDirection: 'column'
  };
  const [loading, setLoading] = useState(false);
  const [connectedStatus, setConnectedStatus] = useState({});
  useEffect(() => {
    const connected = async () => {
      try {
        setLoading(true);
        const status = await getIntegrationData();
        setConnectedStatus(status);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    connected();
  }, []);

  return (
    !loading && (
      <Card elevation={0} sx={integrationsCardsStyle}>
        <div className='integrations_cards_title'>Integrations</div>
        <div className='integrations_cards_subtitle'>
          Sell your products on your social platforms.
        </div>
        <div className='integrations_cards_list'>
          {socials.map((social) => (
            <IntegrationCard
              social={social}
              key={social.name}
              connected={connectedStatus?.[social?.name.toLowerCase()] || false}
            />
          ))}
        </div>
      </Card>
    )
  );
};

IntegrationCards.propTypes = {
  socials: array.isRequired
};

export default IntegrationCards;
