import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSubscriptionService, useUserSubscriptions } from 'hooks';
import { useFeatureFlag, FLAGS } from 'lib/featureFlags';
import { LoaderButton } from '@teespring/ts-components';
import PaymentProcessingModalFlow from 'components/PaymentProcessing';
import get from 'lodash/get';
import PropTypes, { string } from 'prop-types';
import { S3_ASSET_URL, SERVICE_ID_MAP } from '../../../constants';
import './SpringMarkPage.scss';

/**
 * Renders the SpringMark page for tools
 *
 * @return {object} JSX.Element
 * */

const TermsOfService = ({ onClick }) => {
  return (
    <a
      href='https://www.spri.ng/policies/spring-terms-of-service?section=spring-mark-terms-of-service'
      target='_blank'
      rel='noreferrer'
      onClick={onClick}
    >
      <u>SpringMark Terms of Service.</u>
    </a>
  );
};

TermsOfService.propTypes = {
  onClick: PropTypes.func.isRequired
};

const ToolArticle = ({ tag, title, children }) => {
  return (
    <article>
      <span>{tag.toUpperCase()}</span>
      <h2>{title}</h2>
      <section>{children}</section>
    </article>
  );
};

ToolArticle.propTypes = {
  children: PropTypes.node.isRequired,
  tag: string.isRequired,
  title: string.isRequired
};

const SpringMarkPage = () => {
  const { isFetchingServiceInfo } = useSelector((state) => state.subscriptions);
  const termsOfServiceViewed = () => {};

  const subscriptionData = useSubscriptionService(SERVICE_ID_MAP.spring_mark);
  const { priceOptions } = subscriptionData;
  const priceOption = get(priceOptions, 0, {
    cost: 100,
    interval: 'second',
    currency: 'USD'
  });
  const { userId } = useSelector((state) => state.user);
  const springMarkFeatureFlagEnabled = useFeatureFlag(FLAGS.SPRINGMARK);

  const { active: activeSubscriptions } = useUserSubscriptions(userId);
  const isSubscribedToSpringMark = Object.values(activeSubscriptions).some(
    (sub) => sub.creatorToolPrice.creatorTool.id === SERVICE_ID_MAP.spring_mark
  );

  const subscriptionButtonBaseClass = 'spring_mark__subscription_button';
  const originalPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: priceOption.currency,
    minimumFractionDigits: 2
  }).format(19.99);
  const price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: priceOption.currency,
    minimumFractionDigits: 2
  }).format(priceOption.price / 100);

  const subscriptionButtonText = useCallback(
    (isPrimary) => {
      const subscribeNowText = isPrimary
        ? `Subscribe now for ${price}/mo`
        : `Protect all your products for ${price}/mo`;
      return isSubscribedToSpringMark
        ? 'Manage subscription'
        : subscribeNowText;
    },
    [isSubscribedToSpringMark, price]
  );
  const springMarkFormURL = 'https://form.typeform.com/to/giPw7bzi';
  const ineligableBtnAction = () => {
    window.open(springMarkFormURL, '_blank');
  };

  const ineligableBtn = () => (
    <LoaderButton
      className='spring_mark__subscription_button btn light spr_mark__btn'
      text='Join the waitlist'
      onClick={ineligableBtnAction}
      loadingIconOnly={true}
    />
  );

  const subscriptionButton = (button) => {
    return springMarkFeatureFlagEnabled ? (
      <PaymentProcessingModalFlow
        subscriptionData={subscriptionData}
        isSubscribed={isSubscribedToSpringMark}
        paymentModalBtn={button}
        buttonDisabled={!springMarkFeatureFlagEnabled}
        priceOption={priceOption}
        loading={isFetchingServiceInfo}
        descriptiveLogTag={button.logTag}
      />
    ) : (
      ineligableBtn()
    );
  };

  // Primary Button
  const primaryButtonAttributes = useMemo(() => {
    return {
      title: subscriptionButtonText(true),
      class: `${subscriptionButtonBaseClass} light spr_mark__btn`,
      url: '/purchases/subscriptions',
      logTag: 'primary'
    };
  }, [subscriptionButtonBaseClass, subscriptionButtonText]);

  const [primaryPaymentButton, setPrimaryPaymentButton] = useState(
    primaryButtonAttributes
  );

  // Secondary Button
  const secondaryButtonAttributes = useMemo(() => {
    return {
      title: subscriptionButtonText(false),
      class: `${subscriptionButtonBaseClass} ${
        springMarkFeatureFlagEnabled ? 'dark' : 'disabled'
      }
      ${'spr_mark__btn'}`,
      url: '/purchases/subscriptions',
      logTag: 'secondary'
    };
  }, [
    springMarkFeatureFlagEnabled,
    subscriptionButtonBaseClass,
    subscriptionButtonText
  ]);

  const [secondaryPaymentButton, setSecondaryPaymentButton] = useState(
    secondaryButtonAttributes
  );

  useEffect(() => {
    setPrimaryPaymentButton(primaryButtonAttributes);
    setSecondaryPaymentButton(secondaryButtonAttributes);
  }, [
    setPrimaryPaymentButton,
    setSecondaryPaymentButton,
    springMarkFeatureFlagEnabled,
    isSubscribedToSpringMark,
    primaryButtonAttributes,
    secondaryButtonAttributes
  ]);

  const userCanSubscribeToSpringMark =
    springMarkFeatureFlagEnabled && !isSubscribedToSpringMark;

  const assetFromCDN = (asset_path) => `${S3_ASSET_URL}${asset_path}`;

  return (
    <>
      <div className='tool-page'>
        <div className='tool-header'>
          <div className='tool-left-container'>
            <div className='tool-imagotype'>
              <div className='tool-isologo'>
                <img
                  src={assetFromCDN(
                    '/images/dashboard/value_page_springmark/springmark_logo.png'
                  )}
                  alt='springmark logo'
                />
              </div>
              <h1>SpringMark</h1>
            </div>
            <div className='header-text'>
              <div className='tool-description'>
                <h4>
                  Protect your profits—others could be benefiting from your
                  designs. Our technology scans and identifies all your content
                  across 34 popular online marketplaces.
                </h4>
              </div>
              <div className='call-to-action-container'>
                {!isSubscribedToSpringMark &&
                  (springMarkFeatureFlagEnabled ? (
                    <span>
                      50% off{' '}
                      <del>
                        <small>({originalPrice})</small>
                      </del>
                    </span>
                  ) : (
                    <small>
                      We’re rolling out SpringMark to all creators soon.
                      <br />
                      Apply for access below
                    </small>
                  ))}
                {subscriptionButton(primaryPaymentButton)}
              </div>
              <div className='bottom-text'>
                {userCanSubscribeToSpringMark && (
                  <span className='terms-of-service'>
                    By clicking 'Subscribe now' you agree to the{' '}
                    <TermsOfService onClick={termsOfServiceViewed} />
                  </span>
                )}
                <a
                  className='support-link'
                  href='https://www.spri.ng/creator-resources/springmark-faqs'
                  target='_blank'
                  rel='noreferrer'
                >
                  FAQs and Support
                </a>
              </div>
            </div>
            <img
              className='mobile-tool-showcase'
              src={assetFromCDN(
                '/images/dashboard/value_page_springmark/springmark-showcase-mobile.png'
              )}
              alt='tool showcase'
            />
          </div>
          <img
            src={assetFromCDN(
              '/images/dashboard/value_page_springmark/springmark-showcase.png'
            )}
            alt='tool showcase'
            className='desktop-tool-showcase'
          />
        </div>
        <article>
          <span>BENEFITS</span>
          <h2>Why subscribe to SpringMark?</h2>
          <section>
            <img
              alt='Earn what’s yours, avoid losing $10+ whenever a copy of your product is sold'
              src={assetFromCDN(
                '/images/dashboard/value_page_springmark/springmark-profit-lose-card.png'
              )}
            />
            <img
              alt='Save time searching for copies of your designs across the internet'
              src={assetFromCDN(
                '/images/dashboard/value_page_springmark/springmark-automated-searches-card.png'
              )}
            />
            <img
              alt='Safeguard your brand and protect fans from low-quality, copycat products'
              src={assetFromCDN(
                '/images/dashboard/value_page_springmark/springmark-copycat-card.png'
              )}
            />
          </section>
        </article>
        {userCanSubscribeToSpringMark && (
          <div className='call-to-action-container'>
            {subscriptionButton(secondaryPaymentButton)}
            <span>
              By clicking 'Protect all your products' you agree to the{' '}
              <TermsOfService onClick={termsOfServiceViewed} />
            </span>
          </div>
        )}
        <hr />
        <ToolArticle tag='Process' title='How it works'>
          <div className='step-by-step-container'>
            <img
              alt='step by step of the tool'
              src={assetFromCDN(
                '/images/dashboard/value_page_springmark/how_it_works.png'
              )}
            />
          </div>
        </ToolArticle>
      </div>
    </>
  );
};

export default SpringMarkPage;
