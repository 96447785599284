import {
  SAMPLES_SET_PRODUCTS,
  SAMPLES_SET_REGION,
  SAMPLES_SET_CURRENCY,
  SAMPLES_LOADING,
  SAMPLES_ADD_TO_CART,
  SAMPLES_REMOVE_ITEM_FROM_CART,
  SAMPLES_CLEAR_CART,
  SAMPLES_ADD_VARIATION,
  SAMPLES_SET_PAGE_NUM,
  SAMPLES_SET_FETCHING_MORE,
  SAMPLES_HIDE_SHOW_MORE
} from '../actions/samples';

export const INITIAL_STATE = {
  listings: [],
  region: 'USA',
  currency: 'USD',
  loading: false,
  pageNum: 1,
  samplesCart: {},
  variationsMap: {},
  isFetchingMore: false,
  hideLoadMore: false
};

const samplesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAMPLES_SET_PRODUCTS:
      return {
        ...state,
        listings: [...state.listings, ...action.listings]
      };
    case SAMPLES_SET_REGION:
      return {
        ...state,
        region: action.region
      };
    case SAMPLES_SET_CURRENCY:
      return {
        ...state,
        currency: action.currency
      };
    case SAMPLES_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SAMPLES_ADD_TO_CART:
    case SAMPLES_REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        samplesCart: action.cart
      };
    case SAMPLES_CLEAR_CART:
      return {
        ...state,
        samplesCart: INITIAL_STATE.samplesCart,
        variationsMap: INITIAL_STATE.variationsMap
      };
    case SAMPLES_ADD_VARIATION:
      return {
        ...state,
        variationsMap: action.variations
      };
    case SAMPLES_SET_PAGE_NUM:
      return {
        ...state,
        pageNum: action.pageNum
      };
    case SAMPLES_SET_FETCHING_MORE:
      return {
        ...state,
        isFetchingMore: action.isFetchingMore
      };
    case SAMPLES_HIDE_SHOW_MORE:
      return {
        ...state,
        hideLoadMore: true
      };
    default:
      return state;
  }
};

export default samplesReducer;
