import { useEffect } from 'react';
import propTypes from 'prop-types';
import { Icon, bpProps, useClipboardCopy } from '@teespring/ts-components';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveModal } from 'redux/actions';
import './ListingAdminBarUrl.scss';

export const ListingAdminBarUrl = ({ listingUrl }) => {
  const dispatch = useDispatch();
  const { bpIsGT, bpIsLT } = useSelector((state) => ({ ...bpProps(state) }));

  const { showCopyNotification, setShowCopyNotification, copyToClipboard } =
    useClipboardCopy();

  useEffect(() => {
    if (showCopyNotification) {
      const timer = setTimeout(() => {
        setShowCopyNotification(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showCopyNotification, setShowCopyNotification]);

  const onCopyHandler = () => {
    if (!showCopyNotification) {
      copyToClipboard(listingUrl);
    }
  };

  return (
    <div className='adminbar__url'>
      <button
        className='adminbar__urlstring'
        type='button'
        onClick={onCopyHandler}
      >
        {bpIsLT('mobileLg') && showCopyNotification
          ? 'Copied to clipboard!'
          : listingUrl}
      </button>

      <div className='adminbar__shareactions'>
        {bpIsGT('mobileLg') && (
          <ul className='adminbar__shareicons'>
            <li className='icon--facebook'>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${listingUrl}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon size={16} name='Facebook' />
              </a>
            </li>
            <li className='icon--twitter'>
              <a
                href={`https://twitter.com/intent/tweet?text=${listingUrl}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon size={16} name='Twitter' />
              </a>
            </li>
          </ul>
        )}

        {bpIsGT('mobileLg') ? (
          <button
            className='btn adminbar__copybtn'
            type='button'
            onClick={onCopyHandler}
          >
            {showCopyNotification ? (
              <span className='adminbar__confirmation'>Copied!</span>
            ) : (
              <>
                <Icon name='Link' size={14} />
                Copy link
              </>
            )}
          </button>
        ) : (
          <button
            className='btn adminbar__mobileshare'
            type='button'
            onClick={() =>
              dispatch(
                setActiveModal({ id: 'listing-share-modal', mProps: {} })
              )
            }
          >
            <Icon name='Share2' size={18} />
          </button>
        )}
      </div>
    </div>
  );
};

const { string } = propTypes;
ListingAdminBarUrl.propTypes = {
  listingUrl: string.isRequired
};

export default ListingAdminBarUrl;
