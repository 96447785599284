import {
  SET_STORE_PREVIEW_DATA,
  FETCH_STORE_THEME_CONFIG,
  DISCARD_PREVIEW_CHANGES,
  SET_PRISTINE,
  UPDATE_THEME_CONFIG,
  SET_INITIAL_PREVIEW_DATA,
  REMOVE_LOGO_IMAGE,
  REMOVE_HERO_BANNER_IMAGE,
  SET_IS_DIRTY,
  SET_PERSISTED_PREVIEW_DATA
} from '../actions/theme';

export const INITIAL_STATE = {
  isDirty: false,
  isHomeDirty: false,
  isEditorDirty: false,
  initialPreviewData: {},
  previewData: {},
  themeConfig: {},
  persistedPreviewData: {},
  fetchedThemeConfig: {
    storeSlug: '',
    hasFetched: false
  }
};

const storePreviewDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STORE_PREVIEW_DATA:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          ...action.data
        },
        isDirty: action.isDirty,
        isHomeDirty: action.isHomeDirty,
        isEditorDirty: action.isEditorDirty
      };
    case FETCH_STORE_THEME_CONFIG:
      return {
        ...state,
        previewData: {},
        initialPreviewData: {},
        themeConfig: action.payload,
        fetchedThemeConfig: {
          ...state.fetchedThemeConfig,
          storeSlug: action.slug,
          hasFetched: true
        }
      };
    case UPDATE_THEME_CONFIG:
      return {
        ...state,
        previewData: {},
        initialPreviewData: {},
        themeConfig: action.payload
      };
    case DISCARD_PREVIEW_CHANGES:
      return {
        ...state,
        previewData: action.payload
      };
    case SET_PRISTINE:
      return {
        ...state,
        isDirty: false,
        isHomeDirty: false,
        isEditorDirty: false
      };
    case SET_INITIAL_PREVIEW_DATA:
      return {
        ...state,
        initialPreviewData: action.payload
      };
    case REMOVE_LOGO_IMAGE:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          'content.header.logo': ''
        },
        isDirty: true
      };
    case REMOVE_HERO_BANNER_IMAGE:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          'content.heroBanner.containerBg': ''
        },
        isDirty: true
      };
    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: true
      };
    case SET_PERSISTED_PREVIEW_DATA:
      return {
        ...state,
        persistedPreviewData: action.payload
      };
    default:
      return state;
  }
};

export default storePreviewDataReducer;
