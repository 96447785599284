import axios from 'axios';
import { formatAxiosRequestHeaders } from 'lib/helpers';
import { API_HOST } from '../constants';
import { cookieGetValue } from './useCookie';

const integrations = [
  {
    name: 'Store Drop',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/PopupShop.png',
    text: 'Showcase and sell products anywhere.',
    href: '/integrations/storedrop'
  },
  {
    name: 'TikTok',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/tiktok_2x.png',
    text: 'Tag products to your videos.',
    href: '/integrations/tiktok'
  },
  {
    name: 'YouTube',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/youtube_desktop_06_24.png',
    text: 'Tag products to your videos.',
    href: '/integrations/youtube'
  },
  {
    name: 'Twitch',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Platform=TwitchSize=Desktop.svg',
    text: 'Showcase products on your channel.',
    href: '/integrations/twitch'
  },
  {
    name: 'LinkTree',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Platform=LinktreeSize=Desktop.svg',
    text: 'Add <span class="spring_store_bold">Spring</span> store to your Linktree.',
    href: '/integrations/linktree'
  },
  {
    name: 'Discord',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/discord.svg',
    text: 'Send purchase alerts to your channel.',
    href: '/integrations/discord'
  },
  {
    name: 'Beacons',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/beacons.png',
    text: 'Display your Spring store on your Beacons page.',
    href: '/integrations/beacons'
  },
  {
    name: 'OnlyFans',
    logo: 'https://teespring-ass.s3.amazonaws.com/dashboard/Platform=OnlyFansSize=Desktop.svg',
    text: 'Add <span class="spring_store_bold">Spring</span> store to your profile.',
    href: '/integrations/onlyfans'
  }
];
const steps = [
  {
    label: 'Showcase products on your YouTube channel',
    description:
      'Selling on YouTube brings more opportunities for your fans to buy.',
    imageHref:
      'https://teespring-ass.s3.amazonaws.com/dashboard/integrations_yt_06_24.png',
    imageHrefMobile:
      'https://teespring-ass.s3.amazonaws.com/dashboard/integrations_mobile.png'
  },
  {
    label: 'Go beyond links, add products to your Linktree',
    description: 'Turn your bio into a profitable storefront.',
    imageHref:
      'https://teespring-ass.s3.amazonaws.com/dashboard/Linktree_Desktop.png',
    imageHrefMobile:
      'https://teespring-ass.s3.amazonaws.com/dashboard/Linktree_Mobile.png'
  }
  /*
  {
    label: 'Bring your store to your website',
    description:
      'More traffic and more sales when you embed your store on your website.',
    imageHref:
      'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/StoreEmbed_Desktop.png',
    imageHrefMobile: ''
  }
*/
];

export const getIntegrationData = async () => {
  const token = cookieGetValue('token');
  const data = await axios.get(
    `${API_HOST}/dashboard/api/integrations/integrations_link_statuses`,
    formatAxiosRequestHeaders(token)
  );
  return data?.data; //{ twitch: false, youtube: true };
};

const useIntegrations = () => {
  return { integrations, steps, getIntegrationData };
};

export default useIntegrations;
