import {
  RECEIVE_STORE,
  SET_STORE_VERSION,
  SET_UPDATING_STORE,
  SET_INFRINGEMENT_CHECK
} from 'redux/actions';

/**
 * Get Store data from teespring
 * @param  {Object} state  Redux State
 * @param  {Object} action Action data
 * @return {Object}        Returns the state
 */
const storeReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_STORE:
      return { ...state, ...action.storeData, isUpdating: action.isUpdating };
    case SET_STORE_VERSION:
      return {
        ...state,
        storeVersion: action.storeVersion
      };
    case SET_UPDATING_STORE:
      return { ...state, isUpdating: action.isUpdating };
    case SET_INFRINGEMENT_CHECK:
      return {
        ...state,
        infringementCheckComplete: action.infringementCheckComplete
      };
    default:
      return state;
  }
};

export default storeReducer;
