import {
  RECEIVE_ANALYTICS_GRAPHQL,
  SET_DEFAULT_ANALYTICS_GRAPHQL,
  SET_FETCHING_ANALYTICS_GRAPHQL
} from 'redux/actions';

export const INITIAL_STATE = {
  stats: {
    orders: 0,
    order_units: 0,
    cancelled_orders: 0,
    cancelled_order_units: 0,
    profit_made: {
      amount: '$'
    },
    campaign_roots_data: [],
    unit_sources_data: [],
    order_feed: [],
    chart_data: []
  },
  isFetching: false
};

const analyticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_ANALYTICS_GRAPHQL: {
      return {
        ...state,
        ...action.analytics?.data?.analytics?.campaign_data,
        isFetching: action.isFetching
      };
    }
    case SET_FETCHING_ANALYTICS_GRAPHQL: {
      return {
        ...state,
        isFetching: action.isFetching
      };
    }
    case SET_DEFAULT_ANALYTICS_GRAPHQL: {
      return {
        ...INITIAL_STATE,
        isFetching: action.isFetching
      };
    }
    default:
      return state;
  }
};

export default analyticsReducer;
