import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import capitalize from 'lodash/capitalize';

import NotFoundPage from '../NotFoundPage';
import CtaBanner from '../AppSettings/CtaBanner';
import AppsList from './AppsList';

import { switchHyphenForWhitespace } from '../../lib/helpers';
import './AppStoreCategoryPage.scss';
import content from './content.json';

const AppStoreCategoryTemplate = () => {
  const { category } = useParams();

  const pageContent = content.find(
    (appTemplate) => appTemplate.appSlug === category
  );

  if (!pageContent) return <NotFoundPage />;

  const { appList, ctaBanner } = pageContent;

  return (
    <main>
      <Helmet>
        <title>
          Dashboard | Spring | Apps |{' '}
          {capitalize(switchHyphenForWhitespace(category))}
        </title>
      </Helmet>

      <section className='apps-category-page'>
        <CtaBanner
          className='apps-category-page__cta-banner'
          image={ctaBanner.image}
          button={ctaBanner.button}
          text={ctaBanner.text}
          variant='left-aligned-content'
          onLogEvent={() => {}}
        />
        <AppsList
          apps={appList.apps}
          appsCategoryName={appList.appsCategoryName}
        />
      </section>
    </main>
  );
};

export default AppStoreCategoryTemplate;
